/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: 'WorkSans-Regular';
  vertical-align: baseline;
  user-select: none;
}

textarea:focus,
input:focus {
  outline: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  user-select: none;
}

body {
  line-height: 1;
  user-select: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: '';
  content: none;
}

q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

div,
span {
  display: flex;
  flex: 1;
  flex-direction: column;
}

html {
  height: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

p {
  min-height: max-content;
}

@font-face {
  font-family: 'WorkSans-Regular';
  src: url('../../resources/fonts/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-Bold';
  src: url('../../resources/fonts/WorkSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-ExtraBold';
  src: url('../../resources/fonts/WorkSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-ExtraLight';
  src: url('../../resources/fonts/WorkSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-Light';
  src: url('../../resources/fonts/WorkSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url('../../resources/fonts/WorkSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: url('../../resources/fonts/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-Thin';
  src: url('../../resources/fonts/WorkSans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans-Black';
  src: url('../../resources/fonts/WorkSans-Black.ttf') format('truetype');
}
@keyframes forceRedraw {
    from { box-shadow: inset rgba(0,0,0,0) 0 0 0; }
    to { box-shadow: inset rgba(0,0,0,0.0000001) 0 0 0 10px; }
}
html {
  font-family: 'WorkSans-Regular' sans-serif;
}

.nav {
  display: flex;
  width: 100%;
  height: 8vh;
  min-height: 50px;
  padding: 0.5%;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.349);
  flex-direction: row;
  align-items: center;
  /* overflow: hidden; */
  position: fixed;
  background: white;
  z-index: 2;
  top: 0;
}

.navSignRegister {
  background: white;
  background: -webkit-linear-gradient(left, white, #f3fbfc);
  background: linear-gradient(to right, white, #f3fbfc);
}

.navEle {
  flex-direction: row;
  justify-content: center;
  height: -webkit-fill-available;
  margin: 0 auto;
  flex: 1;
}

.navLinks {
  align-items: space-between;
  max-height: 2vh;
  margin: auto;
  flex-direction: row;
  max-width: 40%;
}
.navLinks .purpleLink,
.navLinks .greyLink,
.navLinks a {
  width: 8vw;
}
.navLinks .purpleLink p,
.navLinks .greyLink p,
.navLinks a p {
  width: max-content;
  margin: 0 auto;
}

.navLogoContainer {
  display: flex;
  height: 2.5rem;
  margin-left: 5%;
  max-width: 20%;
  margin-top: auto;
  margin-bottom: auto;
}
.navLogoContainer a {
  min-height: max-content;
}

.adminNav .navLogoContainer {
  height: max-content;
  margin-top: 0;
  margin-bottom: 0;
}

.navLogo {
  display: block;
  justify-content: left;
  height: 2.5rem;
  width: auto;
}

.onboardingNav {
  background: white;
  background: -webkit-linear-gradient(left, white, #e3f6f8);
  background: linear-gradient(to right, white, #e3f6f8);
  box-shadow: unset;
  z-index: 0;
}

.navOnBoardingLogoContainer {
  max-width: max-content;
  max-height: max-content;
  margin: auto;
}

.navBtns {
  margin: auto;
  flex-direction: row;
  align-content: space-between;
}

.navBtnContainer {
  max-width: 45%;
  margin: auto 5%;
}

.profileButtonContainer {
  flex: 1;
  flex-direction: row;
  max-height: max-content;
  min-width: 30%;
  max-width: max-content;
  margin: auto;
}

.profileNameText {
  max-width: max-content;
}

.profileButtonIconContainer {
  max-width: max-content;
  margin: auto;
  margin-left: 0;
}

.profileNameContainer {
  min-width: max-content;
  max-width: max-content;
  max-height: max-content;
  margin: auto;
  margin-right: 5%;
}

.profileButtonIcon {
  max-height: 5vh;
  max-width: 10vh;
  cursor: pointer;
}

.inAppNav {
  background-color: white;
}

.profileMenu {
  position: fixed;
  right: 12vw;
  top: 6.5vh;
}

.profileMenuContainer {
  background-color: white;
  width: 20vw;
  position: absolute;
  right: 0;
  top: 30px;
  min-height: 55vh;
  max-height: 100vh;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.349);
}

.profileMenuImageContainer {
  margin: 15% auto 0 auto;
  max-height: max-content;
}

.profileMenuNameContainer {
  max-width: max-content;
  max-height: max-content;
  margin: 5% auto;
  font-size: 1.5rem;
  color: #4a2d4f;
}

.profileMenu.profileMenuHidden {
  top: -60vh;
  opacity: 0;
  transition: 0.5s;
}
.profileMenu.profileMenuHidden a {
  width:1px;
}

.profileMenu.profileMenuActive {
  right: 12vw;
  top: 6.5vh;
  opacity: 1;
  transition: 0.5s;
}

.accountTypeContainer {
  max-width: max-content;
  margin: 0 auto;
}

.adminNav {
  background: #f07f46;
  background: -webkit-linear-gradient(left, #f07f46, #cf5068);
  background: linear-gradient(to right, #f07f46, #cf5068);
  box-shadow: unset;
  z-index: 2;
}
.adminNav .navEle.navOnboardingLogoContainer {
  max-width: max-content;
}
.adminNav .navEle.navBtns {
  max-width: 25%;
  margin: unset;
}
.adminNav .navLogo {
  width: 70px;
  height: auto;
}
.adminNav .navBtnContainer {
  max-height: 10vh;
}

.pageContainer {
  margin: 5% 15%;
}

.imageRow {
  max-height: 15vh;
  flex-direction: row;
  align-content: space-between;
}

.fullViewportHeight {
  height: 100vh;
  margin: unset;
  padding-bottom: 0;
}

.imageCol {
  max-width: max-content;
  max-height: max-content;
  margin: auto;
}

.footer {
  height: 40vh;
  background-color: #4a2d4f;
  width: 100%;
}

.newsTextBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
}

.newsInput {
  width: 400px;
  height: 34px;
  padding: 0px;
  border-width: 0px;
  border-style: none;
  background-color: transparent;
  color: white;
  letter-spacing: 2px;
  padding-left: 30px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1pt solid #fff;
  margin-right: 15px;
  background-image: url(../../resources/images/appAssets/email.png);
  background-position: 2px 10px;
  background-repeat: no-repeat;
}

.newsletterText {
  text-transform: uppercase;
  font-family: 'WorkSans-Regular';
  color: white;
  width: max-content;
  letter-spacing: 0.1rem;
  font-size: 1rem;
}

.newsletterBox {
  margin: 5% 0 0;
  max-height: max-content;
}

.newsLetterBtn {
  max-width: 20%;
}

.newsletterContentContainer {
  max-width: 50%;
  margin: 0 auto;
}

.siteLinks {
  max-height: max-content;
  flex-direction: row;
  width: max-content;
  margin: 0 auto;
}
.siteLinks .whiteText.separator {
  max-width: max-content;
}
.siteLinks .whiteLink {
  min-width: 5vw;
  margin: unset;
}
.siteLinks .whiteLink .whiteText {
  margin: unset;
  min-width: max-content;
}
.siteLinks .whiteLink:link {
  margin: unset;
  width: 10vw;
}

.copyright {
  font-size: 1rem;
  line-height: 22px;
  vertical-align: middle;
  font-family: 'WorkSans-Medium';
  color: white;
  text-align: center;
  margin: 2% auto;
}

.footerHeader {
  margin: 5% auto 2%;
}

.signUpFooter {
  background-color: #f3fbfc;
  max-height: 20vh;
  position: fixed;
  bottom: 0;
}

.socialFooterButtonsContainer {
  max-height: max-content;
  min-width: 50%;
  margin: 0 auto;
}

.socialButtons {
  flex-direction: row;
  margin-bottom: 10%;
  max-width: 50%;
  margin: 0 auto;
}

.onboardingFooter {
  position: fixed;
  bottom: 0;
  background-color: white;
  max-height: 20vh;
  z-index: 3;
  box-shadow: -1px 1px 18px 3px rgba(0, 0, 0, 0.349);
}

.onboardingFooterHidden {
  bottom: 0;
  background-color: white;
  height: 20vh;
  z-index: 0;
  box-shadow: -1px 1px 18px 3px rgba(0, 0, 0, 0.349);
}

.footerHeaderText {
  font-family: 'WorkSans-Light';
  font-size: 1.3rem;
  text-align: center;
  margin: 2% auto;
}

.enterpriseBox {
  flex-direction: row;
  align-items: center;
  margin: 5% auto;
  min-width: 80vw;
  min-height: 10vh;
  background: #f3fbfc;
  background: -webkit-linear-gradient(
    left,
    #f3fbfc,
    rgba(164, 218, 226, 0.377)
  );
  background: linear-gradient(to right, #f3fbfc, rgba(164, 218, 226, 0.377));
}

.enterpriseBtn {
  max-width: 25%;
  margin: 2%;
  margin-left: auto;
}

.enterpriseText {
  margin-left: 20px;
  color: #4a2d4f;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-family: 'WorkSans-Medium';
  letter-spacing: 0.1rem;
}

.banner {
  width: 100%;
  height: 83.75vh;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerContentContainer {
  max-height: max-content;
  margin: auto;
}

.bannerTitle {
  font-size: 4rem;
  width: 400px;
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
  padding-bottom: 5px;
}

.homeBanner {
  min-height: 84.5vh;
}

.bannerSubtitle {
  font-size: 1.3rem;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
}

.bannerText {
  color: #4a2d4f;
  font-family: 'Work Sans', sans-serif;
}

.bannerAppButtons {
  margin: 0 auto;
  flex-direction: row;
  margin-top: 30px;
}

.bannerAppBtn {
  height: 3.5rem;
  margin: 0px 5px;
  cursor: pointer;
}

.articleBanner {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to right, #f07f46, #cf5068);
  justify-content: space-around;
  height: 140px;
  padding: 10px 0px;
}

.articleBtn {
  width: 80%;
  height: 90%;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 2px;
}

.articleBannerTxt {
  text-align: center;
  font-size: 1rem;
  letter-spacing: 2px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  height: 19px;
  line-height: 19px;
  vertical-align: middle;
}

.articleContainer {
  display: flex;
  flex-direction: row;
  max-height: 190px;
  max-width: 100%;
  margin: 0 auto;
}

.carousel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
}

.conciergeBox {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: 30px auto 80px auto;
  margin-top: 30px;
  margin-bottom: 80px;
  box-shadow: 4px 4px 18px 0px rgba(74, 45, 79, 0.5);
  border-radius: 4px;
}

.conciergePhoto {
  height: 330px;
}

.conciergeInfoBox {
  padding: 5%;
  display: flex;
  background-color: #f3fbfc;
  flex-direction: column;
  justify-content: center;
}

.conciergeBtnContainer {
  max-width: 15%;
  max-height: 10%;
}

.conciergeTitle {
  font-size: 22px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  color: #4a2d4f;
  margin-bottom: 10px;
  line-height: 24px;
  vertical-align: middle;
}

.conciergeInfo {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
  color: #4a2d4f;
  margin-bottom: 30px;
  line-height: 18px;
  vertical-align: middle;
}

.goalSection {
  flex-direction: row;
  max-height: 70vh;
}

.goalImg {
  width: 100%;
  height: 100%;
}

.goalImage {
  width: 50%;
}

.goalInfo {
  background: #f3fbfc;
  background: -webkit-linear-gradient(
    left,
    #f3fbfc,
    rgba(164, 218, 226, 0.377)
  );
  background: linear-gradient(to right, #f3fbfc, rgba(164, 218, 226, 0.377));
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goalBox {
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: 2px 0px 24px 0px rgba(74, 45, 79, 0.08);
  max-height: 10vh;
  max-width: 60%;
  margin: 2% auto;
  padding: 2%;
}

.goalBoxContentContainer {
  flex-direction: row;
  max-height: max-content;
  margin: auto;
}

.goalPointImg {
  width: 35px;
  height: 100%;
  min-height: 25px;
  margin-right: 20px;
}

.goalPointText {
  width: calc(50% - 180);
  color: #4a2d4f;
  margin: auto;
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.goalInfoContentContainer {
  max-height: 50%;
  margin: auto;
}

.goalTitle {
  font-size: 2rem;
  text-align: center;
  width: 60%;
  margin-bottom: 4%;
  max-height: max-content;
}

.signPage {
  flex-direction: row;
}

.signInfo {
  flex: 1;
  max-height: max-content;
  max-width: 35%;
  margin: auto;
}

.signImage {
  flex: 1;
  max-height: 100vh;
  max-width: 50%;
}

.iPhoneImg {
  height: 90%;
  width: 44%;
  margin: auto;
}

.signContentContainer {
  margin: auto;
}

.signTitle {
  line-height: 33px;
  vertical-align: middle;
  color: #4a2d4f;
  font-size: 24px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.signSubtitle {
  color: #4a2d4f;
  font-size: 18px;
  font-family: 'Work Sans', sans-serif;
  margin-bottom: 30px;
  line-height: 20px;
  vertical-align: middle;
}

.signPgBtn {
  padding: 2.5%;
  padding-left: 0;
  min-height: 20%;
  max-width: 45%;
}

.partnerDesc {
  font-family: 'WorkSans-Regular';
  color: #4a2d4f;
}

.partnerSection {
  margin: 2% 0;
}

.partnerImg {
  height: auto;
  width: 90%;
  margin: auto;
}

.partnerImgCont {
  background: white;
}

.partnerSectionHeader {
  margin: auto;
  min-width: 80vw;
  border-bottom: #caeff3 solid 0.15rem;
}

.partnerNameCont,
.partnerDescCont {
  margin-top: 2%;
  max-height: max-content;
}

.partnerContentCont {
  max-height: 50%;
  max-width: 75%;
  padding: 2%;
  margin: auto;
}

.partnerBoxContainer {
  flex-direction: row;
  margin: auto;
  padding-top: 1.5%;
}

.displayBoxesContainer {
  flex-direction: row;
}

.partnerLinkCont {
  margin-top: 7%;
}

.partnerControls {
  max-width: 5vw;
}
.partnerControls img {
  width: 1.25vw;
  height: auto;
  margin: auto;
}
.partnerControls .partnerNextArrow {
  transform: rotate(270deg);
}
.partnerControls .partnerBackArrow {
  transform: rotate(90deg);
}

.partnerBox {
  min-height: 30vh;
  max-height: 30vh;
  flex-direction: row;
  max-width: 40vw;
  box-shadow: 4px 4px 18px rgba(74, 45, 79, 0.08);
  margin: 0px 18px;
}

.partnerName {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: 'WorkSans-SemiBold';
  font-weight: 600;
  letter-spacing: 2px;
  color: #4a2d4f;
}

.visitLink {
  font-size: 18px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  color: #cf5068;
  line-height: 24px;
}

.partnerInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 382px;
  padding-right: 30px;
  padding-left: 30px;
}

.headerTitle {
  font-size: 22px;
  color: #4a2d4f;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  vertical-align: middle;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 20px;
}

.headerDivider {
  width: 80%;
  height: 1pt;
  margin: 0 auto;
  margin-bottom: 30px;
  background-color: #caeff3;
}

.socialMediaBox {
  flex-direction: row;
  justify-content: space-between;
  max-height: max-content;
  margin: 5% auto;
  width: 15vw;
}

.fbSocialMediaImg {
  height: 30px;
  cursor: pointer;
}

.igSocialMediaImg {
  height: 30px;
  cursor: pointer;
}

.twSocialMediaImg {
  height: 30px;
  cursor: pointer;
}

.buttonGroupContainer {
  max-height: max-content;
  margin: 2%;
}

.button {
  padding: 1% 5%;
  border-radius: 5px;
  min-height: 2.5rem;
  cursor: pointer;
  transition: 0.5s;
}

.buttonText {
  margin: auto;
  font-size: 1rem;
  font-family: 'WorkSans-SemiBold';
  text-align: center;
  text-justify: inter-word;
}

.dropdownMenuButton {
  flex-direction: row;
}
.dropdownMenuButton p {
  margin: auto;
  margin-left: 2%;
  max-width: max-content;
  max-height: max-content;
}
.dropdownMenuButton .dropdownArrow {
  margin: auto;
  margin-right: 2%;
  width: 15px;
  height: 10px;
  object-fit: cover;
}

.onboardingButtonContainer {
  max-height: 25%;
  width: 25%;
  flex-direction: row;
  margin: auto;
}

.onboardingStepButton {
  margin: 0 2%;
}

.disabledButton {
  opacity: 0.3;
  cursor: unset;
}

.radioButtonContainer {
  flex-direction: row;
  width: 15vw;
  height: 5vh;
  margin: 2.5% auto;
  cursor: pointer;
}

.radioButton {
  border-radius: 999px;
  border: #ade9ef 0.3rem solid;
  min-height: 20px;
  max-height: 20px;
  width: 20px;
  margin: auto 5%;
  max-width: 20px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.radioButtonText {
  max-width: max-content;
  font-family: 'WorkSans-Regular';
  color: #4a2d4f;
}

.radioButtonTextContainer {
  margin: auto;
  margin-left: 15%;
  max-width: max-content;
  max-height: max-content;
}

.radioActive {
  background-color: #4a2d4f;
}

.checkButtonContainer {
  flex-direction: row;
  max-width: max-content;
  margin: 1.2%;
}

.checkButton {
  border-radius: 2px;
  border: #dbd5dc 0.1rem solid;
  min-height: 25px;
  max-height: 25px;
  min-width: 25px;
  max-width: 25px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  margin: auto;
  margin-right: 10px;
}

.checkButtonText {
  max-width: max-content;
  font-family: 'WorkSans-Regular';
  color: #4a2d4f;
  max-width: max-content;
  max-height: max-content;
}

.checkButtonTextContainer {
  margin: auto;
  min-width: max-content;
  max-height: max-content;
}

.checkImage {
  max-height: inherit;
  margin: 1%;
}

.socialButton {
  max-height: max-content;
  margin-bottom: 5%;
  max-height: 80%;
  max-width: 75%;
  margin: 0 auto;
}

.googleSignInButton {
  max-width: 75%;
  margin: 0 auot;
}

.clearButton {
  border: 2px white solid;
  margin: 2% 0;
}

.redButton {
  background-color: #cf5068;
}

.gradientOrangeButton {
  background: #f07f46;
  background: -webkit-linear-gradient(left, #f07f46, #cf5068);
  background: linear-gradient(to right, #f07f46, #cf5068);
}

.orangeButton {
  background-color: #f07f46;
}

.whiteButton {
  border: 0.2rem #cf5068 solid;
}

.switchButtonContainer {
  width: 4vw;
  min-height: 3.75vh;
  max-height: 3.75vh;
  margin-left: auto;
}
.switchButtonContainer .switchButton {
  width: 1.5vw;
  max-height: 3vh;
  margin: auto;
  background-color: white;
  transition: 0.5s ease;
  border-radius: 999px;
}
.switchButtonContainer .switch.inactiveSwitch {
  background-color: #d7d1e1;
  border-radius: 999px;
  box-shadow: 0px 0px 10px rgba(74, 45, 79, 0.5) inset;
}
.switchButtonContainer
  .switch.inactiveSwitch
  .switchButton.inactiveSwitchButton {
  transition: 0.5s ease;
  margin-left: 4.5%;
}
.switchButtonContainer .switch.activeSwitch {
  background: #f07f46;
  background: -webkit-linear-gradient(left, #f07f46, #cf5068);
  background: linear-gradient(to right, #f07f46, #cf5068);
  border-radius: 999px;
}
.switchButtonContainer .switch.activeSwitch .switchButton.activeSwitchButton {
  transition: 0.5s ease;
  margin-right: 5%;
}

.rowButtonsContainer {
  flex-direction: row;
  max-width: 35vw;
  max-height: max-content;
  margin: auto;
}

.onboardingPhotoGrid {
  max-height: 30vh;
  max-width: 30vw;
  margin: 5% auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.popUpMenuContainer {
  max-height: max-content;
  max-width: max-content;
}

.dotImg {
  width: 1.75vw;
  height: auto;
}

.popUpMenuButtonContainer {
  margin: 0 auto;
}

.popUpMenuButton {
  width: max-content;
  max-height: 9vh;
}

.browseCardPopUpMenuContainer {
  max-height: 5vh;
  margin-left: auto;
  position: relative;
  right: 0;
}

.menuItem {
  margin: 5%;
}

.popUpMenuItemContainerActive {
  position: fixed;
  width: 10vw;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(74, 45, 79, 0.5);
  min-height: max-content;
  opacity: 1;
  z-index: 2;
  transition: 0.5s ease;
}

.popUpMenuItemContainerHidden {
  position: fixed;
  width: 10vw;
  top: -75vh;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(74, 45, 79, 0.5);
  min-height: max-content;
  opacity: 0;
  z-index: 2;
  transition: 0.5s ease;
}

.navSideMenu {
  z-index: 10;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  transition: 0.5s ease;
}
.navSideMenu .navSideMenuCloseButtonContainer {
  max-height: 10vh;
}
.navSideMenu .navSideMenuCloseButtonContainer .closeImg {
  margin-left: auto;
  margin-top: 5%;
  margin-right: 5%;
  width: 10vw;
}
.navSideMenu .menuLinksContainer {
  padding: 1%;
  width: 50vw;
  margin: auto;
  max-height: 65vh;
  height: 55vh;
}
.navSideMenu .menuLinksContainer .navSideMenuLinksContainer .orOptionContainer {
  max-width: max-content;
  margin: 0 auto;
}
.navSideMenu
  .menuLinksContainer
  .navSideMenuLinksContainer
  .orOptionContainer
  p {
  max-width: max-content;
  max-height: max-content;
  margin: auto;
}

.hideSideMenu {
  left: -102vw;
}

.showSideMenu {
  top: 0;
  left: 0;
}

.blueSideMenu {
  background: white;
  background: -webkit-linear-gradient(top, white, #e3f6f8);
  background: linear-gradient(to bottom, white, #e3f6f8);
}

.loggedInSideMenu {
  background-color: #4a2d4f;
}
.loggedInSideMenu .menuLinksContainer {
  margin: auto;
  width: 100%;
  height: 100%;
}

.navSideMenuLink a .textLinkContainer img {
  width: 75px;
  height: 50px;
  margin: auto;
}

.navSideMenuLink a .textLinkContainer p {
  margin: auto;
  color: white;
  max-width: max-content;
}

.greyLink {
  color: rgba(74, 45, 79, 0.5);
  color: #bbbbbb;
}

.greyLink:link {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'WorkSans-Bold', sans-serif;
  text-align: center;
  text-decoration: none;
  letter-spacing: 3px;
  margin: 0 5%;
  max-height: max-content;
}

.greyLink:visited {
  text-align: center;
  text-decoration: none;
  color: #bbbbbb;
}

.greyLink:hover {
  transition: 0.5s ease;
  color: rgba(150, 112, 156, 0.4);
}

.greyLink:active {
  color: rgba(150, 112, 156, 0.4);
}

.greyText {
  color: rgba(74, 45, 79, 0.5);
  color: #bbbbbb;
  font-size: 1rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.greyHeaderText {
  margin: 0 auto;
  color: #bbbbbb;
  font-size: 1.5rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.pinkLink {
  color: rgba(74, 45, 79, 0.5);
}

.pinkLink:link {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'WorkSans-Bold', sans-serif;
  text-align: center;
  text-decoration: none;
  letter-spacing: 3px;
  margin: 0 5%;
  max-height: max-content;
}

.pinkLink:visited {
  text-align: center;
  text-decoration: none;
  color: #cf5068;
}

.pinkLink:hover {
  transition: 0.5s ease;
  color: #cf5068;
}

.pinkLink:active {
  color: #cf5068;
}

.pinkText {
  color: rgba(74, 45, 79, 0.5);
  font-size: 1rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.pinkHeaderText {
  margin: 0 auto;
  font-size: 1.5rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.whiteLink {
  color: rgba(74, 45, 79, 0.5);
  color: white;
}

.whiteLink:link {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'WorkSans-Bold', sans-serif;
  text-align: center;
  text-decoration: none;
  letter-spacing: 3px;
  margin: 0 5%;
  max-height: max-content;
}

.whiteLink:visited {
  text-align: center;
  text-decoration: none;
  color: white;
}

.whiteLink:hover {
  transition: 0.5s ease;
  color: white;
}

.whiteLink:active {
  color: white;
}

.whiteText {
  color: rgba(74, 45, 79, 0.5);
  color: white;
  font-size: 1rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.whiteHeaderText {
  margin: 0 auto;
  color: white;
  font-size: 1.5rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.blackLink {
  color: rgba(74, 45, 79, 0.5);
  color: black;
}

.blackLink:link {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'WorkSans-Bold', sans-serif;
  text-align: center;
  text-decoration: none;
  letter-spacing: 3px;
  margin: 0 5%;
  max-height: max-content;
}

.blackLink:visited {
  text-align: center;
  text-decoration: none;
}

.blackLink:hover {
  transition: 0.5s ease;
}

.blackText {
  color: rgba(74, 45, 79, 0.5);
  color: black;
  font-size: 1rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.blackHeaderText {
  margin: 0 auto;
  font-size: 1.5rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.orangeLink {
  color: rgba(74, 45, 79, 0.5);
  color: #f07f46;
}

.orangeLink:link {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'WorkSans-Bold', sans-serif;
  text-align: center;
  text-decoration: none;
  letter-spacing: 3px;
  margin: 0 5%;
  max-height: max-content;
}

.orangeLink:visited {
  text-align: center;
  text-decoration: none;
}

.orangeLink:hover {
  transition: 0.5s ease;
}

.orangeText {
  color: rgba(74, 45, 79, 0.5);
  color: #f07f46;
  font-size: 1rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.orangeHeaderText {
  margin: 0 auto;
  font-size: 1.5rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.redLink {
  color: rgba(74, 45, 79, 0.5);
  color: #cf5068;
}

.redLink:link {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'WorkSans-Bold', sans-serif;
  text-align: center;
  text-decoration: none;
  letter-spacing: 3px;
  margin: 0 5%;
  max-height: max-content;
}

.redLink:visited {
  text-align: center;
  text-decoration: none;
}

.redLink:hover {
  transition: 0.5s ease;
}

.redText {
  color: rgba(74, 45, 79, 0.5);
  color: #cf5068;
  font-size: 1rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.redHeaderText {
  margin: 0 auto;
  color: #cf5068;
  font-size: 1.5rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.purpleLink {
  color: rgba(74, 45, 79, 0.5);
  color: #4a2d4f;
}

.purpleLink:link {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'WorkSans-Bold', sans-serif;
  text-align: center;
  text-decoration: none;
  letter-spacing: 3px;
  margin: 0 5%;
  max-height: max-content;
}

.purpleLink:visited {
  text-align: center;
  text-decoration: none;
}

.purpleLink:hover {
  transition: 0.5s ease;
}

.purpleText {
  color: rgba(74, 45, 79, 0.5);
  color: #4a2d4f;
  font-size: 1rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

.purpleHeaderText {
  margin: 0 auto;
  color: #4a2d4f;
  font-size: 1.5rem;
  font-family: 'WorkSans-SemiBold', sans-serif;
}

a {
  text-decoration: none;
  color: unset;
}

.lightWeight {
  font-family: 'WorkSans-Light';
}

.regularWeight {
  font-family: 'WorkSans-Regular';
}

.boldWeight {
  font-family: 'WorkSans-Bold';
}

.semiBoldWeight {
  font-family: 'WorkSans-SemiBold';
}

.uppercaseText {
  text-transform: uppercase;
}

.errorMessageText {
  color: #f07f46;
  font-family: 'WorkSans-SemiBold';
}

@media all and (max-width: 850px) {
  .greyLink {
    color: rgba(74, 45, 79, 0.5);
    color: #bbbbbb;
  }
  .greyLink:link {
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'WorkSans-Bold', sans-serif;
    text-align: center;
    text-decoration: none;
    letter-spacing: 3px;
    margin: 1% auto;
    max-height: max-content;
  }
  .greyLink:visited {
    text-align: center;
    text-decoration: none;
    color: #bbbbbb;
  }
  .greyLink:hover {
    transition: 0.5s ease;
    color: rgba(150, 112, 156, 0.4);
  }
  .greyLink:active {
    color: rgba(150, 112, 156, 0.4);
  }
  .greyText {
    color: rgba(74, 45, 79, 0.5);
    color: #bbbbbb;
    font-size: 1rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .greyHeaderText {
    margin: 0 auto;
    color: #bbbbbb;
    font-size: 1.5rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .pinkLink {
    color: rgba(74, 45, 79, 0.5);
  }
  .pinkLink:link {
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'WorkSans-Bold', sans-serif;
    text-align: center;
    text-decoration: none;
    letter-spacing: 3px;
    margin: 1% auto;
    max-height: max-content;
  }
  .pinkLink:visited {
    text-align: center;
    text-decoration: none;
    color: #cf5068;
  }
  .pinkLink:hover {
    transition: 0.5s ease;
    color: #cf5068;
  }
  .pinkLink:active {
    color: #cf5068;
  }
  .pinkText {
    color: rgba(74, 45, 79, 0.5);
    font-size: 1rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .pinkHeaderText {
    margin: 0 auto;
    font-size: 1.5rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .whiteLink {
    color: rgba(74, 45, 79, 0.5);
    color: white;
  }
  .whiteLink:link {
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'WorkSans-Bold', sans-serif;
    text-align: center;
    text-decoration: none;
    letter-spacing: 3px;
    margin: 1% auto;
    max-height: max-content;
  }
  .whiteLink:visited {
    text-align: center;
    text-decoration: none;
    color: white;
  }
  .whiteLink:hover {
    transition: 0.5s ease;
    color: white;
  }
  .whiteLink:active {
    color: white;
  }
  .whiteText {
    color: rgba(74, 45, 79, 0.5);
    color: white;
    font-size: 1rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .whiteHeaderText {
    margin: 0 auto;
    color: white;
    font-size: 1.5rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .blackLink {
    color: rgba(74, 45, 79, 0.5);
    color: black;
  }
  .blackLink:link {
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'WorkSans-Bold', sans-serif;
    text-align: center;
    text-decoration: none;
    letter-spacing: 3px;
    margin: 1% auto;
    max-height: max-content;
  }
  .blackLink:visited {
    text-align: center;
    text-decoration: none;
  }
  .blackLink:hover {
    transition: 0.5s ease;
  }
  .blackText {
    color: rgba(74, 45, 79, 0.5);
    color: black;
    font-size: 1rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .blackHeaderText {
    margin: 0 auto;
    font-size: 1.5rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .orangeLink {
    color: rgba(74, 45, 79, 0.5);
    color: #f07f46;
  }
  .orangeLink:link {
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'WorkSans-Bold', sans-serif;
    text-align: center;
    text-decoration: none;
    letter-spacing: 3px;
    margin: 1% auto;
    max-height: max-content;
  }
  .orangeLink:visited {
    text-align: center;
    text-decoration: none;
  }
  .orangeLink:hover {
    transition: 0.5s ease;
  }
  .orangeText {
    color: rgba(74, 45, 79, 0.5);
    color: #f07f46;
    font-size: 1rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .orangeHeaderText {
    margin: 0 auto;
    font-size: 1.5rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .redLink {
    color: rgba(74, 45, 79, 0.5);
    color: #cf5068;
  }
  .redLink:link {
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'WorkSans-Bold', sans-serif;
    text-align: center;
    text-decoration: none;
    letter-spacing: 3px;
    margin: 1% auto;
    max-height: max-content;
  }
  .redLink:visited {
    text-align: center;
    text-decoration: none;
  }
  .redLink:hover {
    transition: 0.5s ease;
  }
  .redText {
    color: rgba(74, 45, 79, 0.5);
    color: #cf5068;
    font-size: 1rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .redHeaderText {
    margin: 0 auto;
    color: #cf5068;
    font-size: 1.5rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .purpleLink {
    color: rgba(74, 45, 79, 0.5);
    color: #4a2d4f;
  }
  .purpleLink:link {
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'WorkSans-Bold', sans-serif;
    text-align: center;
    text-decoration: none;
    letter-spacing: 3px;
    margin: 1% auto;
    max-height: max-content;
  }
  .purpleLink:visited {
    text-align: center;
    text-decoration: none;
  }
  .purpleLink:hover {
    transition: 0.5s ease;
  }
  .purpleText {
    color: rgba(74, 45, 79, 0.5);
    color: #4a2d4f;
    font-size: 1rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  .purpleHeaderText {
    margin: 0 auto;
    color: #4a2d4f;
    font-size: 1.5rem;
    font-family: 'WorkSans-SemiBold', sans-serif;
  }
  a {
    text-decoration: none;
    color: unset;
  }
  .lightWeight {
    font-family: 'WorkSans-Light';
  }
  .regularWeight {
    font-family: 'WorkSans-Regular';
  }
  .boldWeight {
    font-family: 'WorkSans-Bold';
  }
  .semiBoldWeight {
    font-family: 'WorkSans-SemiBold';
  }
  .uppercaseText {
    text-transform: uppercase;
  }
  .errorMessageText {
    color: #f07f46;
    font-family: 'WorkSans-SemiBold';
  }
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: unset;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: unset;
  border-radius: 5px;
}

.cradleLoader {
  width: 200px;
  height: auto;
  margin: auto;
}

.cradleLoaderContainer {
  justify-content: center;
  height: 100vh;
}

.dotLoader {
  max-width: 10vw;
  height: auto;
}

.roundImageContainer {
  max-width: 100px;
  max-height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.roundImageContainerLarge {
  max-width: 150px;
  max-height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: double 4px transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #cf5068, #f07f46);
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
}

.roundImage {
  display: block;
  margin: 0 auto;
  width: 135px;
  height: 135px;
  border-radius: 50%;
  object-fit: cover;
}

.badge {
  width: 2vw;
  height: auto;
  position: absolute;
  top: 0vh;
  right: 0vh;
  z-index: 1;
}

.badge.textBadge {
  width: max-content;
  border-radius: 999px;
  padding: 2% 5%;
}

.redBadge {
  color: white;
  background-color: #cf5068;
}

.orangeBadge {
  color: white;
  background-color: #f07f46;
}

.badgeContainer {
  position: relative;
}

.draggableMargin {
  width: max-content;
}

.roundImageContainer {
  border: double 5px transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #cf5068, #f07f46);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.profileImageContainer {
  margin: 0 auto;
  max-height: max-content;
}

.draggableImageContainer {
  border-radius: 50%;
  border: 1px solid white;
  max-width: 10vw;
  max-height: 10vw;
  flex: 1 0 calc(33.333% - 20px);
}

.roundImageContainerRegular {
  min-height: 15vh;
  max-height: 15vh;
  max-width: 10vw;
  border-radius: 999px;
  cursor: pointer;
}

.photoGrid {
  max-width: 33%;
  min-width: 33%;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 35vh;
  max-height: 35vh;
}

.sideMenuButtonImage {
  width: 3.5vw;
  margin-left: auto;
  margin-right: 8%;
}

.tabMenuItem {
  width: 100%;
  max-width: 56vw;
  border-top: 1px #f2f0f5 solid;
  flex-direction: row;
  cursor: pointer;
  margin: auto;
  padding: 0.5% 0;
  min-height: 5vh;
}

.tabContentContainer {
  max-width: 50%;
}

.tabHeaderContainerNoData {
  font-family: 'WorkSans-SemiBold';
  color: #4a2d4f;
  max-width: max-content;
  max-height: max-content;
  margin: auto auto auto 15%;
}

.tabDataContainer {
  margin: auto 0;
  color: #4a2d4f;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tabDataText {
  display: inline;
  white-space: normal;
  width: 100%;
}

.hideOverflow {
  overflow: hidden;
}

.tabHeaderContainer {
  font-family: 'WorkSans-Bold';
  text-transform: uppercase;
  letter-spacing: 3px;
  color: rgba(74, 45, 79, 0.5);
  max-width: max-content;
  max-height: max-content;
}

.tabMenuArrowImg {
  width: 1vw;
  height: auto;
  transform: rotate(270deg);
}

.tabMenuArrowContainer {
  max-width: max-content;
  max-height: max-content;
  margin: auto;
  margin-right: 5%;
  margin-left: auto;
}

.tabMenuHeaderContainer {
  width: 100%;
  padding: 1% 0;
  background-color: #f2f0f5;
}
.tabMenuHeaderContainer .tabHeaderContainer {
  max-width: max-content;
  margin-left: 22%;
}
.tabMenuHeaderContainer .tabHeaderContainer .tabHeaderText {
  font-family: 'WorkSans-SemiBold';
  color: #4a2d4f;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media only screen and (max-width: 650px) {
  .tabMenuItem {
    min-width: 90vw;
    border-top: 1px #f2f0f5 solid;
    flex-direction: row;
    cursor: pointer;
    height: 5vh;
  }
}

.modalCurtain {
  width: 100vw;
  height: 110vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
  position: fixed;
  top: 0vh;
  left: 0vw;
  z-index: 900;
  transition: 0.5s;
  overflow: auto;
}

.modalCurtain.modalHidden {
  top: -200vh;
  position: fixed;
}
.modalCurtain.modalHidden .modalCard {
  position: fixed;
  top: -200vh;
}

.hiddenMessageModal {
  z-index: -20 !important;
  opacity: 0;
}

.modalCard {
  width: 25vw;
  height: 35vh;
  margin: 35vh auto;
  background-color: white;
  border-radius: 10px;
}
.modalCard .modalContentContainer {
  padding: 1%;
}
.modalCard .modalContentContainer .modalCancelContainer {
  max-width: max-content;
  margin: 0 0 0 auto;
}
.modalCard .modalContentContainer .modalCancelContainer .modalCancelImg {
  width: 2.5vw;
}
.modalCard .modalContentContainer .modalTextContainer .modalText {
  text-align: center;
  max-width: 75%;
  color: #4a2d4f;
  font-size: 1.5rem;
  margin: 0 auto;
}
.modalCard .modalContentContainer .modalButtonsContainer {
  flex-direction: row;
  max-height: 10vh;
  width: 90%;
  margin: 0 auto;
}
.modalCard .modalContentContainer .modalButtonsContainer .modalOptionButton {
  margin: 1%;
  max-height: 5vh;
}

.curtainDrag {
  top: -10vh;
}

.upgradeModalCard {
  width: 30vw;
  min-height: 60vh;
  margin: 10% auto;
  background-color: white;
  border-radius: 5px;
}
.upgradeModalCard .upgradeModalCardHeader {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: white;
  background: -webkit-linear-gradient(left, white, #e3f6f8);
  background: linear-gradient(to right, white, #e3f6f8);
}
.upgradeModalCard .upgradeModalCardHeader .upgradeModalCloseButton {
  max-height: max-content;
}
.upgradeModalCard
  .upgradeModalCardHeader
  .upgradeModalCloseButton
  .closeButton {
  width: 5vh;
  height: 5vh;
  margin: 1%;
  margin-left: auto;
}
.upgradeModalCard .upgradeModalCardHeader .upgradeModalHeaderContent {
  margin-left: 5%;
  max-width: max-content;
}
.upgradeModalCard .upgradeModalCardHeader .upgradeModalHeaderContent p {
  line-height: 1.2rem;
}
.upgradeModalCard .upgradeModalCardBody {
  height: max-content;
}
.upgradeModalCard .upgradeModalCardBody .upgradeAccountButtonsContainer {
  flex-direction: row;
  flex-wrap: wrap;
}
.upgradeModalCard .upgradeModalCardBody .upgradeModalButton {
  flex: 1 0 34%;
  min-height: 6vh;
  max-height: 6vh;
  max-width: 12vw;
  margin: 2%;
  border: 2px solid #f2f0f5;
  border-radius: 5px;
  padding: 3% 2%;
  transition: 0.5s ease all;
}
.upgradeModalCard .upgradeModalCardBody .badgeContainer {
  margin: 2%;
}
.upgradeModalCard .upgradeModalCardBody .badgeContainer .upgradeModalButton {
  min-width: 87%;
  min-height: 73%;
  padding: 3% 2%;
}
.upgradeModalCard
  .upgradeModalCardBody
  .upgradeModalButton.selectedUpgradeButton {
  border: solid 2px transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #cf5068, #f07f46);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.upgradeModalCard
  .upgradeModalCardBody
  .upgradeAccountButtons
  .upgradeAccountFormActions {
  flex-direction: row;
  align-self: center;
}
.upgradeModalCard
  .upgradeModalCardBody
  .upgradeAccountButtons
  .upgradeAccountFormActions
  .upgradeAccountCancelSubscriptionContainer,
.upgradeModalCard
  .upgradeModalCardBody
  .upgradeAccountButtons
  .upgradeAccountFormActions
  .upgradeAccountUpgradeButtonContainer {
  width: 10vw;
  height: 5vh;
  margin: 0 2%;
}

.matchModalCard {
  width: 35%;
  background: #f07f46;
  background: -webkit-linear-gradient(top, #f07f46, #cf5068);
  background: linear-gradient(to bottom, #f07f46, #cf5068);
  min-height: 50%;
  margin: 10.5% auto;
}
.matchModalCard .matchModalContentContainer {
  width: 80%;
  max-height: 80%;
  margin: 2% auto;
}
.matchModalCard .matchModalContentContainer .messageChatHeaderTextContainer {
  max-width: max-content;
  max-height: max-content;
  margin: 0 auto;
}
.matchModalCard .matchModalContentContainer .messageChatHeaderTextContainer p {
  font-size: 1.5rem;
}
.matchModalCard .matchModalContentContainer .messageChatHeaderImageContainer {
  flex-direction: row;
  max-width: max-content;
  max-height: max-content;
  margin: 5% auto;
}
.matchModalCard .matchModalContentContainer .matchModalText {
  width: 50%;
  margin: 2.5% auto;
  max-height: 15vh;
  text-align: center;
}
.matchModalCard
  .matchModalContentContainer
  .backButtonContainer
  .button.whiteButton {
  background: white;
  border: white 1px solid;
}

input {
  border: none;
  border-bottom: rgba(74, 45, 79, 0.5) 0.1rem solid;
  flex: 1;
  padding-left: 1%;
  font-size: 1.2rem;
  font-family: 'WorkSans-Regular';
  color: #4a2d4f;
  height: 5vh;
  transition: 0.5s ease;
}

input:focus {
  border-bottom: #4a2d4f 0.1rem solid;
  caret-color: #f07f46;
}

.inputContainer {
  margin: 1.5% 2%;
  max-height: max-content;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputLabel {
  max-height: max-content;
}

.inputLabel label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.2s;
}

.inputLabel input:focus + label {
  top: 100%;
  margin-top: -16px;
}

.inputLabelText {
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  font-family: 'WorkSans-Bold';
  color: #4a2d4f;
}

.css-ck2j65-Slider {
  min-width: -webkit-fill-available;
  max-width: -webkit-fill-available;
}

.sliderLabel {
  margin: 5%;
  max-height: max-content;
}

.sliderInput {
  height: 1vh;
  max-height: 1vh;
}

.input {
  flex-direction: row;
  flex: 1;
  align-content: bottom;
  min-width: -webkit-fill-available;
}

.form {
  margin: auto;
  width: 85%;
}

.inputIcon {
  height: 2.5rem;
  width: auto;
  transition: 0.5s ease;
}

.iconFocus {
  height: 2.5rem;
  width: auto;
  transition: 0.5s ease;
}

::-webkit-inner-spin-button {
  display: none;
}

.birthdayInput {
  max-width: 75vw;
  max-height: 5vh;
}

.multiSliderInput {
  width: 100%;
  max-width: 100%;
  min-height: 1vh;
  max-height: 1vh;
  border-radius: 999px;
  box-shadow: 0px 0px 5px rgba(74, 45, 79, 0.5) inset;
}

.multiSliderHandle {
  height: 2vh;
  width: 2vh;
  border-radius: 999px;
  top: -0.5vh;
  background-color: #4a2d4f;
}

.multiSliderContainer {
  min-height: 5vh;
}

.sliderLabelContainer {
  flex-direction: row;
}

.multiSliderBar {
  min-height: 1vh;
}

.multiSliderBar-1 {
  background-color: #ade9ef;
}

.formHeader {
  max-width: max-content;
  max-height: max-content;
  min-height: max-content;
}

.formHeaderText {
  font-size: 1.75rem;
  font-family: 'WorkSans-Medium';
  color: #4a2d4f;
  padding: 5% 0;
  max-width: max-content;
}

select {
  border: none;
  background-color: unset;
  outline: none;
}

select::-ms-expand {
  display: none;
}

.dropdownMenu {
  position: relative;
  font-family: 'WorkSans-Medium';
  color: #f07f46;
  text-transform: uppercase;
  height: 25px;
  min-width: 250px;
  letter-spacing: 0.3rem;
  cursor: pointer;
  max-height: 5vh;
}

.dropdownMenuContainer {
  position: absolute;
  margin: auto;
  min-width: 200px;
  max-height: 500px;
  top: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f3fbfc;
  padding: 1%;
  box-shadow: rgba(74, 45, 79, 0.08) 0px 10px 10px;
  z-index: 3;
}

.dropdownMenuItem {
  padding: 4%;
  min-height: max-content;
}

.selectedMenuItem {
  background-color: #caeff3;
}

.genderForm,
.sexualOrientationForm,
.lookingForForm,
.ModamilyAppIncome,
.nameForm,
.educationForm,
.workForm,
.hometownForm,
.currentLocationForm,
.birthdayForm,
.religionForm,
.politicsForm,
.heightForm,
.eyeColorForm,
.hairColorForm,
.raceForm,
.ethnicityForm,
.coparentRanksForm {
  width: 25vw;
  margin: 0 auto;
}

.birthdayInputContainer {
  flex-direction: row;
  max-width: 75vw;
}

.subtitleTextContainer {
  max-width: max-content;
  max-height: max-content;
}

.answerContainer {
  margin-left: 5%;
}

.subtitle {
  margin: 2.5%;
}

.subtitleText {
  font-family: 'WorkSans-Bold';
  font-size: 1rem;
  letter-spacing: 0.1rem;
  color: #4a2d4f;
}

.ModamilyAppOnboarding {
  background: white;
  background: -webkit-linear-gradient(left, white, #e3f6f8);
  background: linear-gradient(to right, white, #e3f6f8);
  box-shadow: unset;
}

.onboardingContainer {
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(175, 175, 175, 0.5);
  background-color: white;
  margin-top: 10vh;
  min-height: 70vh;
  margin-bottom: 20vh;
}

.conversationCard {
  border: 2px solid rgba(120, 73, 128, 0.5);
  border-radius: 5px;
  padding: 4%;
  margin: 2% auto;
  width: 20vw;
  font-family: 'WorkSans-Regular';
  color: rgba(74, 45, 79, 0.5);
}

.onboardingFormContainer {
  margin: 2.5% auto;
  width: 50%;
}

.questionModal {
  background: #f07f46;
  background: -webkit-linear-gradient(top, #f07f46, #cf5068);
  background: linear-gradient(to bottom, #f07f46, #cf5068);
  max-width: 25vw;
  margin: 10% auto;
  padding: 5%;
}

.convoFormButtonContainer {
  width: 15vw;
  margin: 5% auto;
}

textarea {
  resize: none;
  height: 15vh;
  width: 15vw;
  border: unset;
  font-family: 'WorkSans-Regular';
  caret-color: #f2706a;
  color: #4a2d4f;
}

.textArea {
  margin: 0 auto;
  border: 2px rgba(74, 45, 79, 0.5) solid;
  border-radius: 10px;
  padding: 1.5%;
  color: #4a2d4f;
  font-family: 'WorkSans-Regular';
}

.counterContainer {
  max-width: max-content;
  margin: 0;
  margin-right: 10%;
  margin-left: auto;
}

.selectedQuestionContainer {
  margin: 2% auto;
  max-width: max-content;
}

.cardContainer {
  width: 20vw;
  border: rgba(74, 45, 79, 0.5) 2px solid;
  border-radius: 10px;
  padding: 5%;
}

.whiteHeader {
  font-size: 2rem;
  max-width: max-content;
  margin: 0 auto;
  color: white;
  font-family: 'WorkSans-Regular';
}

.questionModalHEader {
  margin: 5% 0;
}

.questionList {
  align-content: space-around;
}

.convoCardContainer {
  margin: 2% auto;
  max-width: max-content;
}

.profilePhotoContainer {
  max-width: max-content;
  margin: 2% auto 0;
  max-height: max-content;
}

.imageListContainer {
  width: 20vw;
  margin: 2% auto;
}

.rankContainer {
  flex-direction: row;
  max-height: 5vh;
  margin-top: 5%;
}

.rankPositionContainer {
  max-width: 3.5vh;
  max-height: 3.5vh;
  background-color: #ade9ef;
  border-radius: 5px;
  margin: 2.5%;
}

.rankPositionText {
  max-width: max-content;
  margin: auto;
  font-family: 'WorkSans-Regular';
  color: #4a2d4f;
}

.rankQualityContainer {
  border: 2px solid rgba(74, 45, 79, 0.5);
  border-radius: 5px;
  height: 4vh;
  margin: auto;
}

.rankQualityText {
  font-family: 'WorkSans-Regular';
  color: #4a2d4f;
  margin: auto;
  margin-left: 5%;
}

.errorMessageContainer {
  max-width: max-content;
  max-height: max-content;
  margin: 1% auto;
}

.signForm {
  max-height: max-content;
}

.homeBanner {
  background-image: url(../../resources/images/appAssets/homeBanner.png);
}

.ModamilyAppHome {
  -webkit-transform: translate3d(0, 0, 0);
}

.pageNotFoundMessage {
  margin: auto;
  max-height: max-content;
}

.ModamilyAppPageNotFound {
  min-height: 100vh;
  min-width: 100vw;
}

.ModamilyAppRegisterLogin {
  -webkit-transform: translate3d(0, 0, 0);
}

.signFormBtnContainer {
  margin: 0 auto;
  max-height: 5vh;
  max-width: 15vw;
  min-width: 15vw;
}

.facebookLoginButton {
  margin: 0 15%;
  white-space: nowrap;
  overflow: hidden;
  height: 6vh;
  background-position: center;
  border: unset;
  border-bottom: solid 4px #2e4474;
  border-radius: 5px;
  background-color: #3c5a99;
  color: white;
  font-family: 'WorkSans-Regular';
  padding: 4% 0;
  cursor: pointer;
  width: 2.5vw;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.facebookLoginButton i {
  font-size: 2rem;
}

.googleLoginButton {
  font-family: 'WorkSans-Regular';
  padding: 4% 0;
  cursor: pointer;
  width: 2.5vw;
  white-space: nowrap;
  overflow: hidden;
  height: 6vh;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.googleLoginButton div {
  margin: auto !important;
  max-width: max-content;
}
.googleLoginButton span {
  display: none;
}

.formSuggestionTextContainer {
  margin: 0 auto;
  width: 50vw;
}
.formSuggestionTextContainer .loginFormLink {
  max-width: 10vw;
  margin: 0 auto;
}

.formSuggestionText {
  max-width: max-content;
  font-family: 'WorkSans-Regular';
  font-size: 1.2rem;
  text-align: center;
  margin: 3% auto;
}

.ModamilyAppRegisterLogin {
  min-height: 100vh;
}

.carouselMessage {
  max-width: max-content;
  margin: 15% auto;
  margin-bottom: 0;
}
.carouselMessage .cardMessage {
  max-width: max-content;
}
.carouselMessage .cardMessage p {
  font-size: 1.75rem;
  max-width: max-content;
}
.carouselMessage .cardAction {
  margin: 2% auto;
}

.basicInfoComponent {
  min-height: max-content;
}

.profileAnimationLiked,
.profileAnimationDisliked {
  position: fixed;
  height: 45vh;
  width: 45vw;
  transition: 0.5s ease;
}
.profileAnimationLiked img,
.profileAnimationDisliked img {
  width: 200px;
  height: 170px;
  margin: auto;
}

.profileAnimationDisliked {
  background-color: #555;
}

.profileAnimationLiked {
  background-color: #cf5068;
}

.inactiveAnimation {
  opacity: 0;
  z-index: -1;
}

.activeAnimation {
  z-index: 6;
  opacity: 0.75;
}

.ModamilyAppBrowse {
  background: white;
  background: -webkit-linear-gradient(left, white, #d8f4f7);
  background: linear-gradient(to right, white, #d8f4f7);
  -webkit-transform: translate3d(0, 0, 0);
}
.ModamilyAppBrowse:after {
  content:"";
      position:fixed;
      top:8vh;
      height:1000000px;
      left:0;
      right:0;
      z-index:-1;

      background: white;
      background: -webkit-linear-gradient(left, white, #d8f4f7);
      background: linear-gradient(to right, white, #d8f4f7);
      -webkit-transform: translate3d(0, 0, 0);

      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
}

.carouselAlert {
  background-color: #f07f46;
  min-width: 25vw;
  max-width: 25vw;
  max-height: 35vh;
  margin: auto;
  padding: 2% 1%;
  box-shadow: 0px 10px 10px rgba(74, 45, 79, 0.5);
}

.browseLoadingContainer {
  margin: auto;
  max-height: max-content;
}

.carouselControlContaier {
  max-width: max-content;
  margin: 0 auto;
}

.carouselRefresh {
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;
  margin: 5% auto;
  background-color: white;
  border-radius: 50%;
  align-content: center;
}

.reloadImage {
  margin: auto;
  height: auto;
  width: 55px;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}

.carouselRefresh:hover .reloadImage {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}

.carouselRefresh:active .reloadImage {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.browseCarousel {
  position: relative;
  margin-top: 8vh;
}

.browseCarouselCard {
  flex-direction: row;
  max-height: 45vh;
  max-width: 45vw;
  min-width: 45vw;
  margin: auto;
  box-shadow: 0px 0px 15px rgba(74, 45, 79, 0.5);
}

.cardActions {
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-height: max-content;
  max-height: max-content;
}

.profileInfoList {
  max-height: max-content;
  min-height: max-content;
}

.profileSlideshowImage,
.fullProfileSlideshowImage {
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.fullProfileSlideshowImage > img {
  margin: 0 auto;
  display: block;
  height: 100%;
}

.profileSlideshowImage {
  opacity: 0;
  display: none !important;
  position: relative;
}

.profileInfoContainer {
  max-height: 30vh;
}
.profileInfoContainer div {
  min-height: max-content;
}

.profileInfoCard,
.fullProfileInfoCard {
  background-color: white;
  padding: 5%;
  min-height: max-content;
}

.fullProfileRanksCard {
  background: white;
  background: -webkit-linear-gradient(right, white, #d8f4f7);
  background: linear-gradient(to left, white, #d8f4f7);
  min-height: max-content;
  padding: 5%;
}

.likeButtonImage,
.dislikeButtonImage {
  height: 7vh;
  width: 7vh;
}

.profileInfoName {
  font-size: 2rem;
}

.lookingForTitle,
.ageTitle {
  letter-spacing: 0.25rem;
  font-size: 0.8rem;
}

.likeButtonContainer,
.dislikeButtonContainer {
  margin: 8%;
  cursor: pointer;
}

.fullProfileCardActions {
  width: 10vw;
  max-height: max-content;
  align-self: center;
  flex-direction: row;
}
.fullProfileCardActions .likeButtonContainer,
.fullProfileCardActions .dislikeButtonContainer {
  margin: 0 auto;
  max-width: max-content;
  cursor: pointer;
}

.fullProfileModalCurtain {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0vh;
  left: 0vw;
  z-index: 9;
  transition: 0.5s;

  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
}

.profileImageSlideshow,
.fullProfileImageSlideshow {
  max-width: auto;
  height: 100%;
  position: relative;
  cursor: pointer;
  background: rgb(240, 127, 70);
}

.profileImageSlideshow > img {
  max-height: 55vh;
}

.fullProfileColumn {
  width: 45vw;
  border-radius: 10px;
}

.fullProfileContentContainer {
  flex-direction: row;
  width: max-content;
  margin: 5% auto;
  margin-top: 85px;
  height: max-content;
}

.fullProfileCardContainer {
  width: 45vw;
  max-height: 45vh;
  min-height: 45vh;
  flex-direction: row;
}

.fullProfileSlideshowImage.slideImageInactive {
  opacity: 0;
  display: none !important;
}

.profileSlideshowImage.slideActive {
  opacity: 1;
  display: block !important;
  flex-basis: 100% !important;
}

.slideDots {
  margin: 0 auto;
  margin-top: auto;
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  max-height: 3vh;
  width: 10vw;
  top: -3vh;
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
}
.slideDots .slideDot {
  z-index: 1;
  width: 17px;
  height: 17px;
  background-color: rgba(200, 200, 200, 0.4);
  border-radius: 50%;
  margin: 0 3px;
  cursor: pointer;
  display: inline-block;
  flex: none;
}
.slideDots .slideDot.activeDot {
  background-color: rgba(250, 250, 250, 0.8);
}

.exitButtonContainer {
  max-width: max-content;
  max-height: max-content;
}

.exitButtonImage {
  width: 3vw;
  height: 3vw;
}

.fullProfileConversationStarter {
  background: #cf5068;
  background: -webkit-linear-gradient(right, #cf5068, #f07f46);
  background: linear-gradient(to left, #cf5068, #f07f46);
  padding: 2%;
  padding-left: 5%;
  color: white;
  max-height: max-content;
  min-height: max-content;
  font-family: 'WorkSans-Regular';
}
.fullProfileConversationStarter .convoStarterQuestion {
  font-family: 'WorkSans-Light';
  font-size: 1.2rem;
  margin: 1% 0 0.5%;
  max-height: max-content;
}
.fullProfileConversationStarter .convoStarterAnswer {
  font-size: 1.5rem;
  margin: 1% 0 0.5%;
  max-height: max-content;
}

.fullProfileColumn .artistsContainer {
  padding: 0 2%;
  background: white;
}
.fullProfileColumn .artistsContainer .topArtistContainer {
  flex-direction: row;
  min-height: 30vh;
  overflow-x: auto;
  margin: 0 auto;
  max-width: 42.5vw;
}
.fullProfileColumn .artistsContainer .topArtistContainer .artistContainer {
  margin: 1%;
  width: 10vw;
  min-height: 10vh;
}
.fullProfileColumn
  .artistsContainer
  .topArtistContainer
  .artistContainer
  .artistCover {
  margin: auto;
}
.fullProfileColumn
  .artistsContainer
  .topArtistContainer
  .artistContainer
  .artistCover
  img {
  width: 10vw;
  height: auto;
}
.fullProfileColumn
  .artistsContainer
  .topArtistContainer
  .artistContainer
  .artistName {
  margin: 2% auto;
}
.fullProfileColumn
  .artistsContainer
  .topArtistContainer
  .artistContainer
  .artistName
  p {
  width: max-content;
  height: max-content;
}

.modalActive {
  opacity: 1;
}

.fd-row {
  flex-direction: row;
}

.profileItemIconContainer {
  min-height: max-content;
}

.profileItemContainer {
  width: 10vw;
  min-height: max-content;
  max-height: max-content;
  margin-bottom: 3%;
}
.profileItemContainer .profileItemTitleContainer {
  max-width: max-content;
  margin: 3% 0;
}
.profileItemContainer .itemIcon {
  height: 5vh;
  width: 5vh;
}

.fullProfileDetailsContainer {
  max-height: max-content;
}

.profileInfoRow {
  flex-direction: row;
  max-height: max-content;
  min-height: max-content;
}

.profileValuesItem {
  flex-direction: row;
  margin: 1% 0;
}
.profileValuesItem .rankOrderContainer {
  background-color: #ade9ef;
  max-width: 1.5vw;
  height: 1.5vw;
}
.profileValuesItem .rankOrderContainer .rankText {
  color: #4a2d4f;
  max-width: max-content;
  margin: auto;
}
.profileValuesItem .rankValueContainer {
  max-width: max-content;
  color: #4a2d4f;
  margin: auto auto auto 2%;
}
.profileValuesItem .rankValueContainer .rankValue {
  height: max-content;
}

.fullProfileDetailsComponent {
  background-color: white;
  min-height: max-content;
  padding: 5%;
}

.likeUserCard {
  flex-direction: column;
}

.confirmEmailPage {
  width: 25vw;
  max-height: 25vh;
  margin: 10% auto;
}

.confirmEmailButtonContainer {
  width: 15vw;
  height: 5vh;
  margin: 0 2%;
}

.resetPasswordPage {
  width: 35vw;
  margin: 2% auto;
}

.resetPasswordButtonContainer {
  width: 15vw;
  max-height: 5vh;
  margin: auto;
}

.accountDetails {
  margin-top: 8vh;
}

.logoutButtonContainer {
  width: 20vw;
  max-height: 10vh;
  margin: 2% auto 0;
}

.deactivateAccountButtonContainer {
  width: 15vw;
  max-height: 5vh;
  margin: 2% auto;
}

.dangerousActions {
  background-color: #f2f0f5;
}

.accountDetailFormContainer {
  margin: auto;
  margin-top: 20vh;
  width: 30vw;
}

.accountDetailFormActions {
  margin: 5% 0;
  flex-direction: row;
}
.accountDetailFormActions .cancelButtonContainer,
.accountDetailFormActions .submitButtonContainer {
  margin: 0 1%;
  width: 25vw;
  max-height: 5vh;
}

.searchPreferencesMenu,
.editProfileMenu {
  margin-top: 10vh;
}

.editViewButtonContainer {
  flex-direction: row;
  margin: 0 auto;
}
.editViewButtonContainer .editButton,
.editViewButtonContainer .viewButton {
  max-width: 20vw;
  min-width: 20vw;
  margin: 1%;
}

.editProfileForm {
  margin-top: 10vh;
}

.editProfileFormSubmit,
.editProfileFormCancel {
  width: 20vw;
  margin: 0 2%;
}

.overlayPremium {
  background-color: #f2f0f5;
}

.upgradeCard {
  background: #f07f46;
  background: -webkit-linear-gradient(left, #f07f46, #cf5068);
  background: linear-gradient(to right, #f07f46, #cf5068);
  width: 20vw;
  min-height: 20vh;
  max-height: 20vh;
  margin: 2.5% auto;
  border-radius: 5px;
}
.upgradeCard .upgradeCardContent {
  max-width: max-content;
  max-height: max-content;
  margin: auto;
  align-content: center;
}
.upgradeCard .upgradeCardContent p {
  max-width: max-content;
  margin: 0 auto;
  color: white;
}
.upgradeCard .upgradeCardContent .upgradeCardHeader {
  line-height: 1.2rem;
  font-size: 1.2rem;
  width: 10vw;
  text-align: center;
  margin-bottom: 10%;
}

.searchPrefLookingForFormContainer {
  width: 30vw;
  margin: 0 auto;
  max-height: 100vh;
}

.searchPreferencesFormActions {
  flex-direction: row;
  width: 35vw;
  margin: 0 auto;
}

.editProfileFormActions {
  flex-direction: row;
  width: 35vw;
  margin: 2% auto;
}

.searchPrefCancelButton,
.searchPrefSubmitButton {
  width: 20vw;
  margin: 0 1%;
}

.searchPrefDistanceFormContainer {
  width: 25vw;
  height: 50vh;
  margin: 0 auto;
}

.distanceSlider {
  width: 25vw;
  max-height: max-content;
  margin: 5% auto;
}

.searchPrefForm {
  width: 30vw;
  margin: 3% auto;
}

.getLocationButtonContainer {
  max-height: 5vh;
  margin: 2% 0;
}

.ModamilyAppSettings {
  -webkit-transform: translate3d(0, 0, 0);
}

.termsList {
  width: 50vw;
  margin: 0 auto;
}
.termsList .termsSublist {
  width: 35vw;
  margin: 0 auto;
}
.termsList li {
  margin: 2% 0;
}

.ModamilyAppLikes {
  background: white;
  background: -webkit-linear-gradient(left, white, #e3f6f8);
  background: linear-gradient(to right, white, #e3f6f8);
  height: 100vh;
  -webkit-transform: translate3d(0, 0, 0);
}

.likesPageContainer {
  margin: 0 15vw;
  min-height: max-content;
  margin-top: 13vh;
}

.likesPageContainer .likesUserHeader,
.likesPageContainer .likesUserHeader > p {
  margin: 0;
}

.likesPageContainer .matchesContainer,
.likesPageContainer .convosContainer,
.likesPageContainer .likesRowContainer,
.likesPageContainer .likesUserContainer,
.likesPageContainer .likedUserGrid,
.likesPageContainer .userLikesContainer {
  margin-top: 15px;
}

.likesPageContainer .matchesContainer .matchListContainer,
.likesPageContainer .convosContainer .convoListContainer {
  margin-top: 10px;
}

.likesUserContainer .likeNameContainer {
  max-width: max-content;
  max-height: max-content;
  margin: 5% auto;
  color: #4a2d4f;
}

.userLikesContainer {
  min-height: max-content;
  /* max-width: 75vw; */
  max-height: max-content;
}

.userLikesRow {
  flex-direction: row;
  width: 100%;
  overflow: auto;
  margin: 0 auto;
}

.noLikedUsers {
  min-height: 10vh;
  max-height: 10vh;
  /* width: 50vw; */
  background: #f07f46;
  background: -webkit-linear-gradient(left, #f07f46, #cf5068);
  background: linear-gradient(to right, #f07f46, #cf5068);
  border-radius: 999px;
}
.noLikedUsers .noLikedUsersText {
  color: white;
  max-width: max-content;
  margin: auto;
}

.userLikesHeader {
  max-height: max-content;
}

.noUsersLiked {
  border-radius: 10px;
  position: fixed;
  width: 71.5vw;
  height: 80%;
  z-index: 1;
  /* opacity: 0.3; */
  /* height: 25vh;
  width: 25vw;
  left: 38%; */
  /* margin: 5% auto; */
  background: rgba(240, 127, 70, 0.3);
  background: -webkit-linear-gradient(left, rgba(240, 127, 70, 0.3), rgba(207, 80, 104, 0.5));
  background: linear-gradient(to right, rgba(240, 127, 70, 0.3), rgba(207, 80, 104, 0.5));
}

.noUsersLiked-text-container {
  padding-top: 90px;
}

.noUsersLiked .noUsersLikedText {
  font-size: 50px;
  margin: 0 auto;
  color: white;
  max-width: max-content;
}
.noUsersLiked .noUsersLikedLink {
  cursor: pointer;
  font-size: 20px;
  margin: 16px auto;
  color: white;
  max-width: max-content;
  text-decoration: underline;
}

.premiumOverlayBlur {
  filter: blur(35px);
  max-width: max-content;
  max-height: max-content;
  margin: 1% 1%;
  opacity: 0.6;
  cursor: default !important;
}

.likesUserContainer {
  min-height: max-content;
}

.likeUserContainer {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  margin: 1%;
  min-width: 7vw;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.likeUserContainer .likedModel {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-image: url('../../resources/images/appAssets/likedModel.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  flex: none;
}
.likeUserContainer .likedModel::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(240, 127, 70, 0.4),
    rgba(207, 80, 104)
  );
  border-radius: 50%;
}

.likeUserContainer .likedModel span {
  font-family: WorkSans-SemiBold;
  font-size: 20pt;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.likeUserContainer .roundImageContainer {
  min-height: max-content;
  min-width: max-content;
  margin: auto;
}
.likeUserContainer .likeNameContainer {
  max-width: max-content;
  max-height: max-content;
  margin: auto;
  align-self: center;
}

.likesUserHeader {
  max-height: max-content;
  margin: 2% 0;
}

.likedUserGrid {
  width: 100%;
  min-height: max-content;
  flex-direction: row;
  flex-wrap: wrap;
}

.likeUserCard {
  margin: 1% auto;
  width: 33vw;
  max-width: 33vw;
  flex: 1 0 calc(33.333% - 20px);
  min-height: 45vh;
}
.likeUserCard .profileImageSlideshow .profileSlideshowImage {
  height: 55vh;
}
.likeUserCard .profileImageSlideshow .slideDots {
  z-index: 1;
}
.likeUserCard .profileInfoCard .browseCardPopUpMenuContainer {
  max-height: max-content;
  max-width: max-content;
  margin: 0 2%;
  margin-left: auto;
}
.likeUserCard .profileInfoCard .profileInfoContainer .cardActions {
  max-width: max-content;
}

.convoHeaderContainer {
  flex-direction: row;
}
.convoHeaderContainer .sortingBoxContainer {
  margin-left: auto;
  max-width: max-content;
  flex-direction: row;
}

.matchListContainer {
  flex-direction: row;
}

.conversationTab {
  border-top: 1px solid #e3f6f8;
  padding: 1%;
  cursor: pointer;
}
.conversationTab .conversationTabContainer {
  flex-direction: row;
}
.conversationTab .conversationTabContainer .recentMessageContainer {
  max-width: max-content;
  margin: auto;
  margin-left: 3%;
}
.conversationTab
  .conversationTabContainer
  .recentMessageContainer
  .userNameContainer {
  max-height: max-content;
  margin: 3%;
  margin-left: 0;
}
.conversationTab
  .conversationTabContainer
  .recentMessageContainer
  .italicStyle {
  font-style: italic;
}

.messageChatCard {
  min-width: 45vw;
  max-width: 45vw;
  min-height: 90vh;
  max-height: 90vh;
  margin: 2% auto;
  background: white;
  border-radius: 10px;
  -webkit-transform: translate3d(0,0,0);
  animation-name: forceRedraw;
  animation-duration: 4s;
  animation-iteration-count:infinite;
  -webkit-transform:translateZ(1px);
  -moz-transform:translateZ(1px);
  -o-transform:translateZ(1px);
  transform:translateZ(1px);
  backface-visibility: hidden;
  will-change: transform;


}

.messageChatContainer {
  padding: 1%;
}

.messageChatHeaderContainer {
  max-width: 15vw;
  max-height: max-content;
  margin: 0 auto;
}
.messageChatHeaderContainer .messageChatHeaderImageContainer {
  flex-direction: row;
  max-width: max-content;
  max-height: max-content;
  margin: 1% 0;
}
.messageChatHeaderContainer .messageChatHeaderTextContainer {
  max-width: max-content;
  max-height: max-content;
  margin: 0 auto;
}
.messageChatHeaderContainer .messageChatHeaderTextContainer p {
  max-width: max-content;
  max-height: max-content;
  margin: 1% auto;
}
.messageChatHeaderContainer
  .messageChatHeaderTextContainer
  .messageChatHeaderText {
  font-size: 1.5rem;
  color: #4a2d4f;
}

.messageChatBody {
  margin-top: 50px;
}

.messageChatBody .chatView {
  overflow-y: scroll;
  max-height: 38vh;
}

.overlayImageContainer {
  max-height: max-content;
  position: relative;
  z-index: 2;
  right: -1vw;
}

.chatTextInputContainer {
  max-height: 10vh;
  min-height: 10vh;
  padding-left: 2%;
  flex-direction: row;
  border-top: 1px solid #ddd8e5;
}
.chatTextInputContainer .messageChatInput {
  margin: auto;
  border: 1px solid #ddd8e5;
  border-radius: 999px;
  font-size: 1rem;
  padding: 0 2%;
}
.chatTextInputContainer .messageChatButton {
  max-height: max-content;
  max-width: 5vw;
  margin: auto;
  cursor: pointer;
}
.chatTextInputContainer .messageChatButton p {
  max-width: max-content;
  max-height: max-content;
  margin: auto;
}
.chatMessageImg{
  width: 300px;
  height: auto;
}



.chatMessageContainer.currentUserChat .chatMessage {
  background: #ade9ef;
  margin-left: auto;
  margin-right: 10%;
  width: max-content;
  height: max-content;
  max-width: 55%;
  line-height: 1.2rem;
  padding: 1.5%;
  border-radius: 5px;
}

.chatMessageContainer {
  width: 100%;
  min-height: max-content;
}
.chatMessageContainer .chatMessage {
  width: max-content;
  margin: 2%;
  background: #e5e1eb;
  padding: 1.5%;
  margin-top: 5px;
  margin-left: 10%;
  line-height: 1.2rem;
  max-height: max-content;
  height: max-content;
  max-width: 55%;
  border-radius: 5px;
}

.sendImageButton {
  max-width: 2.5vw;
  cursor: pointer;
}
.sendImageButton .sendImageButtonImg {
  width: 30px;
  height: auto;
  margin: auto;
}

.chatTimeContainer {
  height: max-content;
  width: auto;
  text-align: left;
  padding-left: 10%;
  flex: none;
}

.chatTimeContainer.currentUserChat {
  text-align: right;
  padding-right: 10%;
}

.chatTimeContainer > p {
  font-family: WorkSans-SemiBold;
  font-size: 10pt;
  color: rgba(74, 45, 79, 0.4);
}

.messageModalCurtain {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
  position: fixed;
  top: 100vh;
  left: 0vw;
  z-index: 900;
  transition: 0.5s ease;
  -webkit-transform: translate3d(0,0,0);
  animation-name: forceRedraw;
  animation-duration: 4s;
  animation-iteration-count:infinite;
  -webkit-transform:translateZ(1px);
  -moz-transform:translateZ(1px);
  -o-transform:translateZ(1px);
  transform:translateZ(1px);
  backface-visibility: hidden;
  will-change: transform;


}

.messageCurtainDrag {
  top: 0;
}

.ModamilyAppMessages {
  -webkit-transform: translate3d(0, 0, 0);
}

.adminForm {
  width: 45vw;
  margin: 0 auto;
}

.adminUserListContainer {
  max-width: 50%;
  max-height: 100%;
}
.adminUserListContainer .tabMenuHeaderContainer {
  margin: 0;
  min-height: 2.5vh;
  max-height: 2.5vh;
}
.adminUserListContainer .adminUserList {
  max-height: 100%;
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
}
.adminUserListContainer .adminUserList::-webkit-scrollbar {
  width: 0.5rem;
}
.adminUserListContainer .adminUserList::-webkit-scrollbar-track {
  background: #f2f0f5;
}
.adminUserListContainer .adminUserList::-webkit-scrollbar-thumb {
  background: #f07f46;
  border-radius: 5px;
}
.adminUserListContainer .adminUserList .tabMenuHeader {
  margin: 0;
  min-height: 10vh;
  max-height: 10vh;
}
.adminUserListContainer .adminUserList .tabMenuItem {
  margin: 0;
  min-height: 10vh;
  max-height: 10vh;
}

.adminHome {
  /* max-height: 80vh; */
}
.adminHome .fd-row {
  max-height: 80vh;
}
.adminHome .fd-row .adminUserList {
  max-height: 65vh;
}
.adminHome .fd-row .paginationContainer {
  max-height: max-content;
}
.adminHome .fd-row .paginationContainer .pageButton {
  min-width: 2.5vw;
}

.adminEditUser {
  height: max-content;
  overflow-y: auto;
}

.ModamilyAppAdminControl {
  max-height: 100vh;
  min-height: 100vh;
}
.ModamilyAppAdminControl .adminHome {
  overflow: hidden;
}
.ModamilyAppAdminControl .pageContainer {
  margin-bottom: 0;
}
.ModamilyAppAdminControl .formContainer {
  max-width: 75vw;
  width: 75vw;
  max-height: 50vh;
  margin: auto;
}
.ModamilyAppAdminControl .formContainer .exitAdminProfile {
  max-height: 5vh;
}
.ModamilyAppAdminControl .formContainer .button {
  max-width: 30vw;
  max-height: 10vh;
  margin: auto;
}
.ModamilyAppAdminControl .formContainer .genderForm,
.ModamilyAppAdminControl .formContainer .sexualOrientationForm,
.ModamilyAppAdminControl .formContainer .lookingForForm,
.ModamilyAppAdminControl .formContainer .ModamilyAppIncome,
.ModamilyAppAdminControl .formContainer .nameForm,
.ModamilyAppAdminControl .formContainer .educationForm,
.ModamilyAppAdminControl .formContainer .workForm,
.ModamilyAppAdminControl .formContainer .hometownForm,
.ModamilyAppAdminControl .formContainer .currentLocationForm,
.ModamilyAppAdminControl .formContainer .birthdayForm,
.ModamilyAppAdminControl .formContainer .religionForm,
.ModamilyAppAdminControl .formContainer .politicsForm,
.ModamilyAppAdminControl .formContainer .heightForm,
.ModamilyAppAdminControl .formContainer .eyeColorForm,
.ModamilyAppAdminControl .formContainer .hairColorForm,
.ModamilyAppAdminControl .formContainer .raceForm,
.ModamilyAppAdminControl .formContainer .ethnicityForm,
.ModamilyAppAdminControl .formContainer .coparentRanksForm {
  width: 45vw;
  margin: auto;
}

.ModamilyAppAdminControl .adminHome .refreshUserListBtn {
  max-height: 70px;
  margin: 5px 0px;
}

.ModamilyAppAdminControl .adminHome .fd-row > div {
  display: block;
}

.ModamilyAppAdminControl .adminHome .fd-row .sliderControl > div {
  display: block;
}

.ModamilyAppAdminControl .adminHome .dropMenuControls .dropdownMenu {
  min-height: 45px;
}

.filterButton {
  background: #f07f46;
  background: -webkit-linear-gradient(-90deg, #f07f46, #cf5068);
  background: linear-gradient(180deg, #f07f46, #cf5068);
  border-radius: 900px;
  position: fixed;
  z-index: 2;
  width: 50px;
  height: 50px;
  bottom: 10vh;
  right: 5vh;
}

.searchControls {
  height: max-content;
  max-width: 45vw;
  margin: auto;
  margin-right: 5%;
  padding: 1.5%;
  border-radius: 5px;
  box-shadow: 0px 2px 10px #bdb3cc;
}
.searchControls .refreshUserListBtn {
  margin: 2% auto;
  width: 55%;
  height: 5vh;
}
.searchControls .dropdownMenuContainer {
  z-index: 4;
}
.searchControls .buttonControls {
  margin-top: 2%;
  max-height: max-content;
}

.filterImage {
  width: 60%;
  margin: auto;
}

@media (min-width: 800px) {
  .filterButton {
    display: none;
  }
}

@media (max-width: 800px) {
  .adminFilterMenu.adminMenuHidden {
    display: none;
  }
  .adminFilterMenu.adminMenuActive {
    display: block;
  }
}

.heightSwitchButtonContainer {
  max-height: max-content;
}
.heightSwitchButtonContainer .heightSwitchButton .switchButtonContainer {
  margin: 0 auto;
}

.adminUserProfileImage {
  min-height: 35vh;
}
.adminUserProfileImage .profileImageSlideshow {
  min-height: 35vh;
}
.adminUserProfileImage .profileSlideshowImage {
  margin: auto;
  width: 20vw;
  min-height: 35vh;
}

.exitProfileImg {
  width: 5vw;
  height: 8vh;
}

.userProfileName {
  margin: auto;
  max-width: max-content;
}

.convoCardContainer {
  z-index: 0;
}

.deleteUser .formHeader {
  margin: 0 auto;
}

.deleteUser .refreshUserListBtn {
  margin: 0 15%;
}

.adminUserList .adminRowUserContainer {
  flex-direction: row;
  padding: 1%;
  min-height: 110px;
  display: -webkit-flex;
}
.adminUserList .adminRowUserContainer .roundImageContainer {
  margin-right: 1%;
}
.adminUserList .adminRowUserContainer .tabMenuItem {
  margin: unset;
}

.adminRowUserImage {
  max-width: 25%;
  height: auto;
}

.paginationContainer {
  max-height: 10vh;
  margin: 5% 0;
  flex-wrap: wrap;
}
.paginationContainer .pageButton {
  max-width: 2.5vw;
  width: 5vw;
  height: 5vh;
  margin: auto;
}

.adminContentEditCard {
  margin: 2% auto;
  min-width: 50vw;
  max-width: 50vw;
  min-height: 30vh;
  border-radius: 5px;
  box-shadow: 0px 2px 10px #bdb3cc;
}
.adminContentEditCard .contentEditContainer .textArea {
  min-width: 90%;
  margin-bottom: 4%;
}
.adminContentEditCard .contentEditContainer .textArea textarea {
  width: 100%;
}
.adminContentEditCard .adminContentButtonsContainer {
  min-width: 80%;
  margin: 0 auto;
  margin-bottom: 4%;
}
.adminContentEditCard
  .adminContentButtonsContainer
  .adminEditContentButtonContainer {
  margin: 0 auto;
  max-width: 10vw;
}

.ModamilyAppMaintenance {
  background: white;
  background: -webkit-linear-gradient(left, white, #d8f4f7);
  background: linear-gradient(to right, white, #d8f4f7);
  height: 100vh;
}

.MaintenanceMessage {
  margin: auto;
  max-width: 50%;
  max-height: 30vh;
}

.ModamilyLogo {
  height: auto;
  width: 50%;
  margin: auto;
  margin-bottom: 5%;
}

.MaintenanceText {
  color: #4a2d4f;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.5rem;
}

@media all and (max-width: 1050px) {
  .slideDots {
    width: auto;
  }
  .slideDots .slideDot {
    min-width: 15px;
    max-width: 15px;
    max-height: 15px;
  }
  .profileMenuContainer {
    min-width: 30vw;
  }
  .inputLabel {
    font-size: 1rem;
  }
  .formHeaderText {
    font-size: 1.2rem;
  }
  .buttonGroupContainer .radioButtonContainer {
    width: 100%;
  }
  .buttonGroupContainer .radioButtonContainer .radioButton {
    margin: 5% unset;
    min-width: 20px;
    height: 20px;
    width: 20px;
  }
  .buttonGroupContainer .radioButtonContainer .radionButtonTextContainer {
    width: 100%;
  }
  .roundImageContainerRegular {
    min-height: unset;
    max-height: unset;
    max-width: unset;
  }
  .dropdownMenu {
    margin: 5% auto;
  }
  .dropdownMenu .dropdownMenuContainer {
    max-height: 40vh;
  }
  .badge {
    width: 25px;
  }
  .roundImage {
    width: 100px;
    height: 100px;
  }
  .questionModal {
    margin: 10% 0;
    padding: 15% 5%;
    max-width: 90%;
    width: 100vw;
  }
  .questionModal .questionModalHeader {
    margin: 2% 0;
  }
  .questionModal .questionModalHeader p {
    font-size: 1.4rem;
  }
  .questionModal .questionList {
    max-width: 75%;
    margin: 0 auto;
  }
  .questionModal .questionList .button {
    margin: 4% 0;
  }
  .fullProfileContentContainer {
    margin: auto;
    margin-top: 85px;
    max-width: 100vw;
    height: unset;
  }
  .fullProfileContentContainer .exitButtonContainer {
    position: relative;
    right: 10vw;
    top: 5px;
  }
  .fullProfileContentContainer .exitButtonContainer .exitButtonImage {
    width: 35px;
    height: auto;
  }
  .fullProfileContentContainer .fullProfileCardContainer {
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow {
    max-height: 45vh;
    min-height: 45vh;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow
    .slideDots {
    width: auto;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow
    .slideDots
    .slideDot {
    max-width: 15px;
    height: 15px;
  }
  .fullProfileContentContainer .fullProfileCardContainer .fullProfileInfoCard {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .profileInfoNameContainer,
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .lookingForContainer,
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .ageContainer {
    margin: 2% 0;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .browseCardPopUpMenuContainer {
    max-height: 20px;
    min-height: 20px;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .browseCardPopUpMenuContainer
    .dotImg {
    width: 30px;
    height: 10px;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .profileInfoContainer {
    max-height: unset;
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileInfoCard {
    flex-direction: column;
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileInfoCard .basicInfoComponent {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileInfoCard
    .basicInfoComponent
    .profileInfoList {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileRanksCard {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileRanksCard .profileValuesItem {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileRanksCard
    .profileValuesItem
    .rankOrderContainer {
    min-width: 10vw;
    min-height: 5vh;
  }
  .fullProfileContentContainer
    .fullProfileRanksCard
    .profileValuesItem
    .rankValueContainer {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileCardActions {
    width: max-content;
  }
  .fullProfileContentContainer .fullProfileCardActions .likeButtonContainer,
  .fullProfileContentContainer .fullProfileCardActions .dislikeButtonContainer {
    margin: 5%;
  }
  .fullProfileColumn {
    width: 100%;
  }
  .fullProfileColumn .artistsContainer {
    padding: 0 2%;
    background: white;
  }
  .fullProfileColumn .artistsContainer .topArtistContainer {
    flex-direction: row;
    min-height: 30vh;
    overflow-x: auto;
    margin: 0 auto;
    max-width: 42.5vw;
  }
  .fullProfileColumn .artistsContainer .topArtistContainer .artistContainer {
    margin: 1%;
    width: 10vw;
    min-height: 10vh;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistCover {
    margin: auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistCover
    img {
    width: 10vw;
    height: auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistName {
    margin: 2% auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistName
    p {
    width: max-content;
    height: max-content;
  }
  .switchButtonContainer {
    width: 60px;
  }
  .switchButtonContainer .switchButton {
    width: 25px;
  }
  .tabMenuItem {
    min-height: 10vh;
    max-height: 15vh;
  }
  .tabMenuItem .tabContentContainer {
    max-height: 12vh;
    max-width: 100%;
    margin: auto;
    font-size: 1rem;
  }
  .tabMenuItem .tabActionContainer {
    max-height: max-content;
    margin: auto;
    max-width: 3vh;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer {
    width: 60px;
    height: 45px;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer .switchButton {
    width: 25px;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer .switch {
    width: 55px;
  }
  .tabMenuHeaderContainer {
    min-height: 5vh;
  }
  .tabMenuHeaderContainer .tabHeaderContainer {
    margin: auto;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions
    .upgradeAccountCancelSubscriptionContainer,
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions
    .upgradeAccountUpgradeButtonContainer {
    min-width: max-content;
    max-width: max-content;
    min-height: max-content;
    max-height: max-content;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions {
    min-width: 50%;
    max-width: 50%;
  }
  .upgradeModalCard {
    margin: unset;
    width: 100%;
    position: relative;
    top: 10vh;
    height: 100vh;
    min-height: 100%;
  }
  .upgradeModalCard .upgradeModalCardHeader {
    min-height: 30vh;
    max-height: 30vh;
    padding-bottom: 10%;
  }
  .upgradeModalCard .upgradeModalCardBody {
    min-height: max-content;
  }
  .upgradeModalCard .upgradeModalCardBody .upgradeAccountButtonsContainer {
    padding: 10% 0;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer {
    max-width: 50vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer
    .badge {
    top: -2vh;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer
    .upgradeModalButton {
    min-width: 42vw;
    max-height: 25vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .upgradeModalButton {
    max-width: 50vw;
    max-height: 25vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .upgradeAccountFormActions {
    min-width: max-content;
  }
  .popUpMenuContainer .popUpMenuButtonContainer .dotImg {
    width: 20px;
  }
  .popUpMenuContainer .popUpMenuItemContainerActive {
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
  .popUpMenuContainer .popUpMenuItemContainerHidden {
    top: -100vh;
    width: unset;
    height: unset;
    opacity: 1;
  }
  .input .inputIcon,
  .input .iconFocus {
    display: none;
  }
  .modalCard {
    width: 100vw;
    height: max-content;
    margin: 40vh 0;
  }
  .modalCard .modalContentContainer .modalCancelContainer {
    max-height: max-content;
  }
  .modalCard .modalContentContainer .modalCancelContainer .modalCancelImg {
    width: 20px;
  }
  .modalCard .modalContentContainer .modalTextContainer {
    max-height: max-content;
    margin: 5% 0;
  }
  .modalCard .modalContentContainer .modalButtonsContainer {
    max-height: max-content;
  }
  .modalCard .modalContentContainer .modalButtonsContainer .modalOptionButton {
    max-height: max-content;
  }
  .matchModalCard {
    width: 100%;
    height: 100%;
    margin-top: 0vh;
  }
  .matchModalCard .matchModalContentContainer {
    max-height: max-content;
    margin: auto;
  }
  .matchModalCard .matchModalContentContainer .matchModalText {
    width: 75%;
  }
  .matchModalCard .matchModalContentContainer .matchModalActions {
    max-height: 35%;
  }
  .likeButtonContainer,
  .dislikeButtonContainer {
    max-width: max-content;
  }
  .googleLoginButton {
    width: 5vw;
    height: 5vw;
  }
  .editViewButtonContainer {
    flex-direction: row;
    min-height: 13vh;
    margin: 0 auto;
  }
  .editViewButtonContainer .editButton,
  .editViewButtonContainer .viewButton {
    max-width: 30vw;
    min-width: 30vw;
    max-height: 10vh;
    margin: auto 5%;
  }
}

@media all and (max-width: 1050px) {
  .ModamilyAppAdminControl {
    overflow: unset;
  }
  .ModamilyAppAdminControl .pageContainer {
    margin: unset;
    margin-top: 10vh;
  }
}

@media all and (max-width: 800px) {
  .adminUserListContainer {
    max-width: 100vw;
  }
  .adminUserListContainer .adminUserList .tabMenuItem {
    max-width: 90%;
    margin: auto;
  }
  .adminFilterMenu {
    margin-top: 5%;
    position: fixed;
    width: 100vw;
    max-width: 100vw;
    background: white;
    flex-direction: column;
  }
  .adminFilterMenu .dropdownMenu.dropdownMenuContainer {
    z-index: 3;
  }
  .buttonControls {
    max-width: 45vw;
    margin: auto;
  }
  .adminMenuHidden {
    left: -100vw;
  }
  .adminMenuActive {
    left: 0;
  }
  .adminNav.nav .navLogoContainer .navLogo {
    width: 10vw;
    height: auto;
  }
}

@media all and (max-width: 1050px) {
  .ModamilyAppBrowse .browseCardScrollContainer {
    min-height: max-content;
    margin: 5% 0;
  }
  .ModamilyAppBrowse .browseCardScrollContainer .browseCarouselCard {
    flex-direction: column;
    max-width: 85vw;
    max-height: 75vh;
    width: 85vw;
    height: 75vh;
  }
  .ModamilyAppBrowse
    .browseCardScrollContainer
    .browseCarouselCard
    .profileInfoContainer {
    max-height: unset;
  }
  .ModamilyAppBrowse
    .browseCardScrollContainer
    .browseCarouselCard
    .profileInfoContainer
    div {
    min-height: max-content;
  }
  .ModamilyAppBrowse .carouselAlert {
    max-width: unset;
    width: 80vw;
  }
  .ModamilyAppBrowse .carouselAlert .cardMessage {
    margin: 0 auto;
  }
  .ModamilyAppBrowse .carouselAlert .cardMessage p {
    font-size: 1.3rem;
  }
  .ModamilyAppBrowse .carouselAlert .carouselControlContainer {
    min-height: max-content;
  }
  .ModamilyAppBrowse .carouselAlert .carouselControlContainer .carouselRefresh {
    min-width: 45px;
    width: 45px;
    min-height: 45px;
    max-height: 45px;
  }
  .ModamilyAppBrowse
    .carouselAlert
    .carouselControlContainer
    .carouselRefresh
    .reloadImage {
    width: 35px;
    height: auto;
  }
  .fullProfileContentContainer {
    margin: auto;
    margin-top: 85px;
    max-width: 80vw;
    height: unset;
  }
  .fullProfileContentContainer .exitButtonContainer {
    position: relative;
    right: 10vw;
    top: 5px;
  }
  .fullProfileContentContainer .exitButtonContainer .exitButtonImage {
    width: 35px;
    height: auto;
  }
  .fullProfileContentContainer .fullProfileCardContainer {
    flex-direction: column;
    max-width: 80w;
    width: 80vw;
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow {
    max-height: 45vh;
    min-height: 45vh;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow
    .slideDots {
    width: auto;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow
    .slideDots
    .slideDot {
    max-width: 15px;
    height: 15px;
  }
  .fullProfileContentContainer .fullProfileCardContainer .fullProfileInfoCard {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .profileInfoNameContainer,
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .lookingForContainer,
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .ageContainer {
    margin: 2% 0;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .browseCardPopUpMenuContainer {
    max-height: 20px;
    min-height: 20px;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .browseCardPopUpMenuContainer
    .dotImg {
    width: 30px;
    height: 10px;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .profileInfoContainer {
    max-height: unset;
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileInfoCard {
    flex-direction: column;
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileInfoCard .basicInfoComponent {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileInfoCard
    .basicInfoComponent
    .profileInfoList {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileRanksCard {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileRanksCard .profileValuesItem {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileRanksCard
    .profileValuesItem
    .rankOrderContainer {
    min-width: 10vw;
    min-height: 5vh;
  }
  .fullProfileContentContainer
    .fullProfileRanksCard
    .profileValuesItem
    .rankValueContainer {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileCardActions {
    width: max-content;
  }
  .fullProfileContentContainer .fullProfileCardActions .likeButtonContainer,
  .fullProfileContentContainer .fullProfileCardActions .dislikeButtonContainer {
    margin: 5%;
  }
}

@media all and (max-width: 1050px) {
  .likesPageContainer {
    margin: 12vh 5%;
  }
  .likesPageContainer .userLikesContainer .userLikesRow {
    margin: 0 auto;
    overflow: auto;
  }
  .likesPageContainer .likeUserContainer {
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    margin: 1%;
    max-width: max-content;
  }
  .likesPageContainer .likesUserContainer .noUsersLiked {
    width: 75vw;
    left: 12.5%;
  }
  .likesPageContainer .likesUserContainer .noUsersLiked .noUsersLikedText {
    max-width: 80%;
    text-align: center;
  }
  .likesPageContainer .likesUserContainer .likedUserGrid {
    flex-direction: column;
    /* max-width: 75vw; */
    margin: auto;
  }
  .likesPageContainer .likesUserContainer .likeUserCard {
    max-width: 75vw;
    width: 75vw;
  }
}

@media all and (max-width: 1050px) {
  .ModamilyAppMessages {
    max-width: 100vw;
  }
  .ModamilyAppMessages .likesPageContainer {
    max-height: 100vh;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .matchesContainer
    .matchListContainer
    .likeUserContainer {
    min-width: max-content;
    max-width: max-content;
  }
  .ModamilyAppMessages .likesPageContainer .convosContainer {
    margin: 5% 0;
  }
  .ModamilyAppMessages .likesPageContainer .messageChatCard {
    margin: 5vh 0;
    max-width: unset;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .browseCardPopUpMenuContainer {
    margin-top: 2.5vh;
    margin-right: 5vw;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatHeaderContainer {
    min-width: max-content;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatHeaderContainer
    .messageChatHeaderImageContainer {
    margin: 0 auto;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatBody
    .chatView
    .chatMessageContainer
    .chatMessage
    p {
    word-break: break-all;
  }



  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .chatTextInputContainer
    .sendImageButton {
    max-width: 10vw;
    margin-right: 2%;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .chatTextInputContainer
    .messageChatButton {
    max-width: 20vw;
  }
  .ModamilyAppMessages .likesPageContainer .convoHeaderContainer {
    flex-direction: column;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoHeaderContainer
    .sortingBoxContainer {
    flex-direction: column;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab {
    max-width: 100vw;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab
    .roundImageContainerRegular {
    max-width: max-content;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab
    .roundImageContainerRegular
    .roundImage {
    margin: unset;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .recentMessageContainer
    p {
    word-break: break-word;
  }
  .ModamilyAppMessages .likesPageContainer .hiddenMessageModal {
    width: 0;
    height: 0;
  }
}

@media all and (max-width: 850px) {
  .slideDots {
    width: auto;
  }
  .slideDots .slideDot {
    min-width: 15px;
    max-width: 15px;
    max-height: 15px;
  }
  .profileMenuContainer {
    z-index: 0;
    box-shadow: unset;
  }
  .inputLabel {
    font-size: 1rem;
  }
  .nav {
    max-width: 100vw;
    height: 10vh;
    align-content: space-between;
  }
  .nav .navEle {
    margin: 0;
    max-width: max-content;
  }
  .nav .navLinks {
    display: none;
  }
  .nav .navBtns {
    align-content: space-between;
  }
  .nav .navBtns .navBtnContainer {
    margin: auto;
    height: max-content;
  }
  .nav .navBtns .navBtnContainer .button {
    margin: auto;
    max-height: 25px;
    margin: unset;
    padding: unset;
  }
  .nav .navOnboardingLogoContainer {
    margin: auto;
    height: max-content;
  }
  .nav .navOnboardingLogoContainer .navLogo {
    width: 155px;
    height: 35px;
  }
  .nav .navLogoContainer {
    margin-left: 5%;
    height: max-content;
  }
  .nav .navLogoContainer .navLogo {
    width: 125px;
    height: 25px;
  }
  .formHeaderText {
    font-size: 1.2rem;
  }
  .buttonGroupContainer .radioButtonContainer {
    width: 100%;
  }
  .buttonGroupContainer .radioButtonContainer .radioButton {
    margin: 5% unset;
    min-width: 20px;
    height: 20px;
    width: 20px;
  }
  .buttonGroupContainer .radioButtonContainer .radionButtonTextContainer {
    width: 100%;
  }
  .roundImageContainerRegular {
    min-height: unset;
    max-height: unset;
    max-width: unset;
  }
  .dropdownMenu {
    margin: 5% auto;
  }
  .dropdownMenu .dropdownMenuContainer {
    max-height: 40vh;
  }
  .badge {
    width: 25px;
  }
  .roundImage {
    width: 100px;
    height: 100px;
  }
  .questionModal {
    margin: 10% 0;
    padding: 15% 5%;
    max-width: 90%;
    width: 100vw;
  }
  .questionModal .questionModalHeader {
    margin: 2% 0;
  }
  .questionModal .questionModalHeader p {
    font-size: 1.4rem;
  }
  .questionModal .questionList {
    max-width: 75%;
    margin: 0 auto;
  }
  .questionModal .questionList .button {
    margin: 4% 0;
  }
  .fullProfileContentContainer {
    margin: auto;
    margin-top: 85px;
    max-width: 100vw;
    height: unset;
  }
  .fullProfileContentContainer .exitButtonContainer {
    position: relative;
    right: 10vw;
    top: 5px;
  }
  .fullProfileContentContainer .exitButtonContainer .exitButtonImage {
    width: 35px;
    height: auto;
  }
  .fullProfileContentContainer .fullProfileCardContainer {
    flex-direction: column;
    max-width: 100vw;
    width: 45vw;
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow {
    max-height: 45vh;
    min-height: 45vh;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow
    .slideDots {
    width: 50%;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow
    .slideDots
    .slideDot {
    max-width: 15px;
    height: 15px;
  }
  .fullProfileContentContainer .fullProfileCardContainer .fullProfileInfoCard {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .profileInfoNameContainer,
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .lookingForContainer,
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .ageContainer {
    margin: 2% 0;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .browseCardPopUpMenuContainer {
    max-height: 20px;
    min-height: 20px;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .browseCardPopUpMenuContainer
    .dotImg {
    width: 30px;
    height: 10px;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .profileInfoContainer {
    max-height: unset;
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileInfoCard {
    flex-direction: column;
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileInfoCard .basicInfoComponent {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileInfoCard
    .basicInfoComponent
    .profileInfoList {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileRanksCard {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileRanksCard .profileValuesItem {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileRanksCard
    .profileValuesItem
    .rankOrderContainer {
    min-width: 10vw;
    min-height: 5vh;
  }
  .fullProfileContentContainer
    .fullProfileRanksCard
    .profileValuesItem
    .rankValueContainer {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileCardActions {
    width: max-content;
  }
  .fullProfileContentContainer .fullProfileCardActions .likeButtonContainer,
  .fullProfileContentContainer .fullProfileCardActions .dislikeButtonContainer {
    margin: 5%;
  }
  .fullProfileColumn {
    width: 45vw;
  }

  .fullProfileColumn .artistsContainer {
    padding: 0 2%;
    background: white;
  }
  .fullProfileColumn .artistsContainer .topArtistContainer {
    flex-direction: row;
    min-height: 30vh;
    overflow-x: auto;
    margin: 0 auto;
    max-width: 90vw;
  }
  .fullProfileColumn .artistsContainer .topArtistContainer .artistContainer {
    margin: 1%;
    width: 25vw;
    min-height: 15vh;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistCover {
    margin: auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistCover
    img {
    width: 20vw;
    height: auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistName {
    margin: 2% auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistName
    p {
    width: max-content;
    height: max-content;
  }
  .switchButtonContainer {
    width: 60px;
  }
  .switchButtonContainer .switchButton {
    width: 25px;
  }
  .tabMenuItem {
    min-height: 10vh;
    max-height: 15vh;
  }
  .tabMenuItem .tabContentContainer {
    max-height: 12vh;
    max-width: 100%;
    margin: auto;
    font-size: 1rem;
  }
  .tabMenuItem .tabActionContainer {
    max-height: max-content;
    margin: auto;
    max-width: 3vh;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer {
    width: 60px;
    height: 45px;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer .switchButton {
    width: 25px;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer .switch {
    width: 55px;
  }
  .tabMenuHeaderContainer {
    min-height: 5vh;
  }
  .tabMenuHeaderContainer .tabHeaderContainer {
    margin: auto;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions
    .upgradeAccountCancelSubscriptionContainer,
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions
    .upgradeAccountUpgradeButtonContainer {
    min-width: max-content;
    max-width: max-content;
    min-height: max-content;
    max-height: max-content;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions {
    min-width: 50%;
    max-width: 50%;
  }
  .upgradeModalCard {
    margin: unset;
    width: 100%;
    position: relative;
    top: 10vh;
    height: 100vh;
    min-height: 100%;
  }
  .upgradeModalCard .upgradeModalCardHeader {
    min-height: 30vh;
    max-height: 30vh;
    padding-bottom: 10%;
  }
  .upgradeModalCard .upgradeModalCardBody {
    min-height: max-content;
  }
  .upgradeModalCard .upgradeModalCardBody .upgradeAccountButtonsContainer {
    padding: 10% 0;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer {
    max-width: 50vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer
    .badge {
    top: -2vh;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer
    .upgradeModalButton {
    min-width: 42vw;
    max-height: 25vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .upgradeModalButton {
    max-width: 50vw;
    max-height: 25vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .upgradeAccountFormActions {
    min-width: max-content;
  }
  .popUpMenuContainer .popUpMenuButtonContainer .dotImg {
    width: 20px;
  }
  .popUpMenuContainer .popUpMenuItemContainerActive {
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
  .popUpMenuContainer .popUpMenuItemContainerHidden {
    top: -100vh;
    width: unset;
    height: unset;
    opacity: 1;
  }
  .input .inputIcon,
  .input .iconFocus {
    display: none;
  }
  .modalCard {
    width: 100vw;
    height: max-content;
    margin: 40vh 0;
  }
  .modalCard .modalContentContainer .modalCancelContainer {
    max-height: max-content;
  }
  .modalCard .modalContentContainer .modalCancelContainer .modalCancelImg {
    width: 20px;
  }
  .modalCard .modalContentContainer .modalTextContainer {
    max-height: max-content;
    margin: 5% 0;
  }
  .modalCard .modalContentContainer .modalButtonsContainer {
    max-height: max-content;
  }
  .modalCard .modalContentContainer .modalButtonsContainer .modalOptionButton {
    max-height: max-content;
  }
  .matchModalCard {
    width: 100%;
    height: 100%;
    margin-top: 0vh;
  }
  .matchModalCard .matchModalContentContainer {
    max-height: max-content;
    margin: auto;
  }
  .matchModalCard .matchModalContentContainer .matchModalText {
    width: 75%;
  }
  .matchModalCard .matchModalContentContainer .matchModalActions {
    max-height: 35%;
  }
  .distanceSlider {
    width: 75vw;
  }
  .currentLocationForm {
    width: 75vw;
    min-height: max-content;
    margin-bottom: 5%;
  }
  .likeButtonContainer,
  .dislikeButtonContainer {
    max-width: max-content;
  }
  .sideMenuButtonImage {
    width: 6vw;
    margin-left: auto;
    margin-right: 8%;
  }
}

@media all and (max-width: 850px) {
  .ModamilyAppHome {
    min-width: 100vw;
  }
  .banner {
    position: unset;
    width: inherit;
    text-align: unset;
    justify-content: unset;
    align-items: unset;
  }
  .homeBanner {
    margin-top: 5vh;
    background-size: auto 100%;
    background-position-x: 35%;
    max-width: 100%;
    height: 50vh;
  }
  .homeBanner .bannerContentContainer {
    max-width: max-content;
    margin: auto;
  }
  .homeBanner .bannerContentContainer .bannerText {
    font-size: 2rem;
    width: 75vw;
    margin: 0 auto;
    height: max-content;
  }
  .homeBanner .bannerContentContainer .bannerSubtitle {
    font-size: 1.2rem;
    width: 75vw;
    margin: 0 auto;
    text-align: center;
  }
  .homeBanner .bannerContentContainer .bannerAppBtn {
    width: 35vw;
    height: 10vh;
  }
  .signImage {
    display: none;
  }
  .goalSection {
    flex-direction: column;
    max-height: unset;
  }
  .goalSection .goalImage {
    width: 100%;
    height: max-content;
  }
  .goalSection .goalInfo {
    width: 100%;
  }
  .goalSection .goalInfo .goalInfoContentContainer {
    max-height: unset;
    margin: 5% auto;
  }
  .goalSection .goalInfo .goalInfoContentContainer .goalTitle {
    margin: 5% auto;
  }
  .goalSection .goalInfo .goalInfoContentContainer .goalBox {
    max-height: unset;
  }
  .goalSection .goalInfo .goalInfoContentContainer .goalBox .goalPointImg {
    width: 25px;
    margin: auto 5%;
  }
  .goalSection .goalInfo .goalInfoContentContainer .goalBox .goalPointText {
    font-size: 0.8rem;
  }
  .conciergeBox {
    flex-direction: column;
  }
  .conciergeBox .conciergePhoto {
    width: 100%;
    height: auto;
  }
  .conciergeBox .conciergeBtnContainer {
    width: 25vw;
    margin: 0 auto;
    max-width: unset;
  }
  .newsletterBox {
    display: none;
  }
  .siteLinks {
    display: none;
  }
  .socialMediaBox {
    margin: unset;
    margin: 5% auto;
    min-width: 75vw;
  }
  .socialMediaBox a {
    margin: 0 auto;
  }
  .separator {
    display: none;
  }
  .articleContainer {
    max-height: max-content;
  }
  .articleContainer .articleBtn {
    width: 92vw;
    height: 5vh;
    margin: 2% auto;
  }
  .signPage .signInfo {
    margin: unset;
    max-width: 100%;
  }
  .signPage .signInfo .signContentContainer {
    max-width: 90vw;
    margin: 15% auto;
  }
  .signPage .signInfo .signContentContainer .signTitle {
    text-align: center;
  }
  .signPage .signInfo .signContentContainer .signSubtitle {
    text-align: center;
  }
  .signPage .signInfo .signContentContainer .signPgBtnContainer {
    flex-direction: column;
    max-width: max-content;
    max-height: max-content;
    margin: 0 auto;
  }
  .signPage .signInfo .signContentContainer .signPgBtnContainer .signPgBtn {
    max-width: 100%;
  }
  .partnerSection .enterpriseBox {
    flex-direction: column;
    margin: 4% auto;
    padding: 4% 0;
  }
  .partnerSection .enterpriseBox .enterpriseText {
    margin: 2% auto;
    max-width: max-content;
    text-align: center;
  }
  .partnerSection .enterpriseBox .enterpriseBtn {
    margin: unset;
    max-width: 75%;
  }
  .partnerSection .displayBoxesContainer {
    flex-direction: column;
    width: 95vw;
  }
  .partnerSection .displayBoxesContainer .partnerBox {
    max-width: 80vw;
    margin: 10% auto;
    flex-direction: column;
    max-height: max-content;
  }
  .partnerSection .displayBoxesContainer .partnerBox .partnerImg {
    max-height: auto;
    width: 40vw;
  }
  .copyright {
    margin: 5% auto;
    font-size: 0.6rem;
    width: max-content;
    line-height: unset;
    vertical-align: unset;
    text-align: unset;
  }
}

@media all and (max-width: 850px) {
  .ModamilyAppRegisterLogin .pageContainer {
    margin: unset;
  }
  .ModamilyAppRegisterLogin .pageContainer .form {
    max-height: max-content;
  }
  .ModamilyAppRegisterLogin .pageContainer .form .passwordRequirements {
    max-height: max-content;
  }
  .ModamilyAppRegisterLogin
    .pageContainer
    .form
    .passwordRequirements
    .purpleText {
    font-size: 0.8rem;
  }
  .ModamilyAppRegisterLogin
    .pageContainer
    .form
    .inputContainer
    .inputLabelText {
    font-size: 0.7rem;
    letter-spacing: 0.15rem;
  }
  .ModamilyAppRegisterLogin .pageContainer .form .signFormBtnContainer {
    max-width: unset;
    min-height: max-content;
    margin: unset;
  }
  .ModamilyAppRegisterLogin .pageContainer .form .formSuggestionTextContainer {
    max-height: max-content;
  }
  .ModamilyAppRegisterLogin
    .pageContainer
    .form
    .formSuggestionTextContainer
    .formSuggestionText,
  .ModamilyAppRegisterLogin
    .pageContainer
    .form
    .formSuggestionTextContainer
    a
    p {
    font-size: 1rem;
  }
  .ModamilyAppRegisterLogin .footer .footerHeaderText {
    font-size: 1rem;
  }
  .ModamilyAppRegisterLogin .footer .socialButtons {
    max-width: max-content;
    margin: auto;
  }
  .ModamilyAppRegisterLogin .footer .socialButtons .googleLoginButton {
    width: 15vw;
    height: 15vw;
  }
  .ModamilyAppRegisterLogin .footer .socialButtons span {
    max-width: max-content;
  }
  .ModamilyAppRegisterLogin .footer .socialButtons span .facebookLoginButton {
    width: 15vw;
    height: 15vw;
  }
}

@media all and (max-width: 850px) {
  .ModamilyAppConfirmEmailPage .pageContainer {
    margin: unset;
    min-height: 100vh;
  }
  .ModamilyAppConfirmEmailPage .pageContainer .confirmEmailPage {
    margin: 25% auto;
    max-height: unset;
    width: 80vw;
  }
  .ModamilyAppConfirmEmailPage
    .pageContainer
    .confirmEmailPage
    .formHeaderText {
    font-size: 1rem;
  }
  .ModamilyAppConfirmEmailPage .footer .rowButtonsContainer {
    max-width: 80vw;
    min-width: 80vw;
    max-height: 10vh;
    margin: auto;
  }
  .ModamilyAppConfirmEmailPage
    .footer
    .rowButtonsContainer
    .confirmEmailButtonContainer {
    max-height: 8vh;
    width: 20vw;
  }
}

@media all and (max-width: 850px) {
  .ModamilyAppOnboarding .onboardingContainer {
    min-height: 80vh;
  }
  .ModamilyAppOnboarding .onboardingContainer .onboardingFormContainer {
    margin: 10% auto;
    width: 90%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .textArea
    textarea {
    width: 60vw;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .convoFormButtonContainer {
    width: 45vw;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .selectedQuestionContainer {
    max-width: unset;
    width: 75%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .selectedQuestionContainer
    .cardContainer {
    margin: auto;
    min-width: 75%;
    width: 80%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .conversationCardContainer
    .convoCardContainer {
    max-width: unset;
    width: 75%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .conversationCardContainer
    .convoCardContainer
    .conversationCard {
    width: 80%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .onboardingImageListContainer
    .onboardingPhotoGrid {
    margin: auto;
    max-width: unset;
    width: 80%;
    height: 20vh;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .onboardingImageListContainer
    .onboardingPhotoGrid
    .draggableImageContainer {
    max-width: 30%;
    margin: 1%;
    max-height: unset;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .genderForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .sexualOrientationForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .lookingForForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .ModamilyAppIncome,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .nameForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .educationForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .workForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .hometownForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .currentLocationForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .birthdayForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .religionForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .politicsForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .heightForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .eyeColorForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .hairColorForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .raceForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .ethnicityForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .coparentRanksForm {
    width: 90%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .rankQualityContainer {
    min-height: max-content;
  }
  .ModamilyAppOnboarding .footer {
    max-height: 15vh;
  }
  .ModamilyAppOnboarding .footer .onboardingButtonContainer {
    width: 80%;
    max-height: max-content;
    margin: auto;
  }
  .ModamilyAppOnboarding
    .footer
    .onboardingButtonContainer
    .onboardingStepButton {
    height: max-content;
  }
}

@media all and (max-width: 850px) {
  .ModamilyAppBrowse .browseCardScrollContainer {
    min-height: max-content;
    margin: 5% 0;
  }
  .ModamilyAppBrowse .browseCardScrollContainer .browseCarouselCard {
    flex-direction: column;
    max-width: 85vw;
    max-height: 75vh;
    width: 85vw;
    height: 75vh;
  }
  .ModamilyAppBrowse
    .browseCardScrollContainer
    .browseCarouselCard
    .profileInfoContainer {
    max-height: unset;
  }
  .ModamilyAppBrowse
    .browseCardScrollContainer
    .browseCarouselCard
    .profileInfoContainer
    div {
    min-height: max-content;
  }
  .ModamilyAppBrowse .carouselAlert {
    max-width: unset;
    width: 80vw;
  }
  .ModamilyAppBrowse .carouselAlert .cardMessage {
    margin: 0 auto;
  }
  .ModamilyAppBrowse .carouselAlert .cardMessage p {
    font-size: 1.3rem;
  }
  .ModamilyAppBrowse .carouselAlert .carouselControlContainer {
    min-height: max-content;
  }
  .ModamilyAppBrowse .carouselAlert .carouselControlContainer .carouselRefresh {
    min-width: 45px;
    width: 45px;
    min-height: 45px;
    max-height: 45px;
  }
  .ModamilyAppBrowse
    .carouselAlert
    .carouselControlContainer
    .carouselRefresh
    .reloadImage {
    width: 35px;
    height: auto;
  }
}

@media all and (max-width: 850px) {
  .likesPageContainer {
    margin: 12vh 5%;
  }
  .likesPageContainer .userLikesContainer .userLikesRow {
    margin: 0 auto;
    overflow-x: auto;
  }
  .likesPageContainer .likeUserContainer {
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    margin: 1%;
    max-width: max-content;
  }
  .likesPageContainer .likesUserContainer .noUsersLiked {
    width: 75vw;
    left: 12.5%;
  }
  .likesPageContainer .likesUserContainer .noUsersLiked .noUsersLikedText {
    max-width: 80%;
    text-align: center;
  }
  .likesPageContainer .likesUserContainer .likedUserGrid {
    flex-direction: column;
    /* max-width: 75vw; */
    margin: auto;
  }
  .likesPageContainer .likesUserContainer .likeUserCard {
    max-width: 75vw;
    width: 75vw;
  }
}

@media all and (max-width: 850px) {
  .ModamilyAppMessages {
    max-width: 100vw;
  }
  .ModamilyAppMessages .likesPageContainer {
    max-height: 100vh;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .matchesContainer
    .matchListContainer
    .likeUserContainer {
    min-width: max-content;
    max-width: max-content;
  }
  .ModamilyAppMessages .likesPageContainer .convosContainer {
    margin: 5% 0;
  }
  .ModamilyAppMessages .likesPageContainer .messageChatCard {
    margin: 5vh 0;
    max-width: unset;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .browseCardPopUpMenuContainer {
    margin-top: 2.5vh;
    margin-right: 5vw;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatHeaderContainer {
    min-width: max-content;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatHeaderContainer
    .messageChatHeaderImageContainer {
    margin: 0 auto;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatBody
    .chatView
    .chatMessageContainer
    .chatMessage
    p {
    word-break: break-word;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .chatTextInputContainer
    .sendImageButton {
    max-width: 10vw;
    margin-right: 2%;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .chatTextInputContainer
    .messageChatButton {
    max-width: 20vw;
  }
  .ModamilyAppMessages .likesPageContainer .convoHeaderContainer {
    flex-direction: column;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoHeaderContainer
    .sortingBoxContainer {
    flex-direction: column;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab {
    max-width: 100vw;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab
    .roundImageContainerRegular {
    max-width: max-content;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab
    .roundImageContainerRegular
    .roundImage {
    margin: unset;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .recentMessageContainer
    p {
    word-break: break-word;
  }
  .ModamilyAppMessages .likesPageContainer .hiddenMessageModal {
    width: 0;
    height: 0;
  }
}

@media all and (max-width: 850px) {
  .ModamilyAppSettings .accountDetails,
  .ModamilyAppSettings .searchPreferencesPage,
  .ModamilyAppSettings .editProfileMenu {
    margin-top: 10.5vh;
  }
  .ModamilyAppSettings .pageContainer {
    margin: 10vh 5%;
  }
  .ModamilyAppSettings .pageContainer .searchPreferencesPage {
    margin-top: unset;
  }
  .ModamilyAppSettings
    .pageContainer
    .searchPreferencesPage
    .searchPrefLookingForFormContainer {
    max-width: max-content;
    width: max-content;
    margin: unset;
  }
  .ModamilyAppSettings .accountDetailFormContainer {
    min-width: 80vw;
  }
  .ModamilyAppSettings .accountDetailFormContainer .nameForm {
    margin: unset;
    width: 100%;
  }
  .ModamilyAppSettings .searchPreferencesMenu {
    margin: unset;
  }
  .ModamilyAppSettings .searchPreferencesMenu .upgradeCard {
    min-width: 75%;
  }
  .ModamilyAppSettings .searchPreferencesMenu .upgradeCard .upgradeCardHeader {
    min-width: 100%;
  }
  .ModamilyAppSettings .searchPrefForm {
    width: 90vw;
  }
  .ModamilyAppSettings .searchPrefForm .searchPrefDistanceFormContainer {
    width: unset;
    height: unset;
    margin: unset;
  }
  .ModamilyAppSettings .dangerousActions {
    min-height: 25vh;
  }
  .ModamilyAppSettings .dangerousActions .deactivateAccountButtonContainer {
    width: 35vw;
    max-height: 5vh;
    margin: auto;
  }
  .ModamilyAppSettings .dangerousActions .logoutButtonContainer {
    margin: auto;
    width: 55vw;
    max-height: 5vh;
  }
  .ModamilyAppSettings .editProfileMenu .photoGrid {
    margin: 5% auto;
    max-width: 90%;
  }
  .ModamilyAppSettings .editProfileMenu .photoGrid .draggableImageContainer {
    max-width: unset;
    max-height: unset;
  }
  .ModamilyAppSettings .editProfileMenu .convoCardContainer {
    max-width: unset;
    width: 75%;
  }
  .ModamilyAppSettings .editProfileMenu .convoCardContainer .conversationCard {
    width: 80%;
  }
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .genderForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .sexualOrientationForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .lookingForForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .ModamilyAppIncome,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .nameForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .educationForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .workForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .hometownForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .currentLocationForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .birthdayForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .religionForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .politicsForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .heightForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .eyeColorForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .hairColorForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .raceForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .ethnicityForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .coparentRanksForm {
    width: 90vw;
  }
  .ModamilyAppSettingsHome .profileMenu.profileMenuActive {
    position: unset;
    margin-top: 10vh;
  }
  .ModamilyAppSettingsHome
    .profileMenu.profileMenuActive
    .profileMenuContainer {
    width: 100vw;
    max-height: 100vh;
    min-height: 90vh;
  }
  .ModamilyAppSettingsHome
    .profileMenu.profileMenuActive
    .profileMenuContainer
    .profileMenuContent
    .tabMenuItem
    .tabContentContainer {
    max-width: 75%;
    margin: auto 0;
  }
  .ModamilyAppSettingsHome
    .profileMenu.profileMenuActive
    .profileMenuContainer
    .profileMenuContent
    .tabMenuItem
    .tabActionContainer {
    max-height: 10vh;
    max-width: 50%;
  }
  .ModamilyAppSettingsHome
    .profileMenu.profileMenuActive
    .profileMenuContainer
    .profileMenuContent
    .tabMenuItem
    .tabActionContainer
    .tabMenuArrowContainer
    .tabMenuArrowImg {
    width: 5vw;
  }
}

@media all and (max-width: 850px) {
  .termsOfServicePage,
  .ModamilyPrivacyPolicy {
    margin: 10vh 0;
  }
  .termsOfServicePage .pageContainer,
  .ModamilyPrivacyPolicy .pageContainer {
    margin: 5%;
  }
}

@media all and (max-width: 400px) {
  .inputLabel {
    font-size: 1rem;
  }
  .distanceSlider {
    width: 75vw;
  }
  .profileMenuContainer {
    z-index: 0;
    box-shadow: unset;
  }
  .currentLocationForm {
    width: 75vw;
    min-height: max-content;
    margin-bottom: 5%;
  }
  .nav {
    max-width: 100vw;
    height: 10vh;
    align-content: space-between;
  }
  .nav .navEle {
    margin: 0;
    max-width: max-content;
  }
  .nav .navLinks {
    display: none;
  }
  .nav .navBtns {
    align-content: space-between;
  }
  .nav .navBtns .navBtnContainer {
    margin: auto;
    height: max-content;
  }
  .nav .navBtns .navBtnContainer .button {
    margin: auto;
    max-height: 25px;
    margin: unset;
    padding: unset;
  }
  .nav .navOnboardingLogoContainer {
    margin: auto;
    height: max-content;
  }
  .nav .navOnboardingLogoContainer .navLogo {
    width: 155px;
    height: 35px;
  }
  .nav .navLogoContainer {
    margin-left: 5%;
    height: max-content;
  }
  .nav .navLogoContainer .navLogo {
    width: 125px;
    height: 25px;
  }
  .formHeaderText {
    font-size: 1.2rem;
  }
  .buttonGroupContainer .radioButtonContainer {
    width: 100%;
  }
  .buttonGroupContainer .radioButtonContainer .radioButton {
    margin: 5% unset;
    min-width: 20px;
    height: 20px;
    width: 20px;
  }
  .buttonGroupContainer .radioButtonContainer .radionButtonTextContainer {
    width: 100%;
  }
  .roundImageContainerRegular {
    min-height: unset;
    max-height: unset;
    max-width: unset;
  }
  .dropdownMenu {
    margin: 5% auto;
  }
  .dropdownMenu .dropdownMenuContainer {
    max-height: 40vh;
  }
  .badge {
    width: 35px;
  }
  .roundImage {
    width: 105px;
    height: 105px;
  }
  .questionModal {
    margin: 10% 0;
    padding: 15% 5%;
    max-width: 90%;
    width: 100vw;
  }
  .questionModal .questionModalHeader {
    margin: 2% 0;
  }
  .questionModal .questionModalHeader p {
    font-size: 1.4rem;
  }
  .questionModal .questionList {
    max-width: 75%;
    margin: 0 auto;
  }
  .questionModal .questionList .button {
    margin: 4% 0;
  }
  .fullProfileContentContainer {
    margin: auto;
    margin-top: 85px;
    max-width: 100vw;
    height: unset;
  }
  .fullProfileContentContainer .exitButtonContainer {
    position: relative;
    right: 12vw;
    top: 5px;
  }
  .fullProfileContentContainer .exitButtonContainer .exitButtonImage {
    width: 35px;
    height: auto;
  }
  .fullProfileContentContainer .fullProfileCardContainer {
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileImageSlideshow {
    max-height: 45vh;
    min-height: 45vh;
  }
  .fullProfileContentContainer .fullProfileCardContainer .fullProfileInfoCard {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .profileInfoNameContainer,
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .lookingForContainer,
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .ageContainer {
    margin: 2% 0;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .browseCardPopUpMenuContainer {
    max-height: 20px;
    min-height: 20px;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .browseCardPopUpMenuContainer
    .dotImg {
    width: 30px;
    height: 10px;
  }
  .fullProfileContentContainer
    .fullProfileCardContainer
    .fullProfileInfoCard
    .profileInfoContainer {
    max-height: unset;
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileInfoCard {
    flex-direction: column;
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileInfoCard .basicInfoComponent {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileInfoCard
    .basicInfoComponent
    .profileInfoList {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileRanksCard {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileRanksCard .profileValuesItem {
    min-height: max-content;
  }
  .fullProfileContentContainer
    .fullProfileRanksCard
    .profileValuesItem
    .rankOrderContainer {
    min-width: 10vw;
    min-height: 5vh;
  }
  .fullProfileContentContainer
    .fullProfileRanksCard
    .profileValuesItem
    .rankValueContainer {
    min-height: max-content;
  }
  .fullProfileContentContainer .fullProfileCardActions {
    width: max-content;
  }
  .fullProfileContentContainer .fullProfileCardActions .likeButtonContainer,
  .fullProfileContentContainer .fullProfileCardActions .dislikeButtonContainer {
    margin: 5%;
  }
  .fullProfileColumn {
    width: 100%;
  }
  .fullProfileColumn .artistsContainer {
    padding: 0 2%;
    background: white;
  }
  .fullProfileColumn .artistsContainer .topArtistContainer {
    flex-direction: row;
    min-height: 30vh;
    overflow-x: auto;
    margin: 0 auto;
    max-width: 90vw;
  }
  .fullProfileColumn .artistsContainer .topArtistContainer .artistContainer {
    margin: 1%;
    width: 25vw;
    min-height: 15vh;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistCover {
    margin: auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistCover
    img {
    width: 20vw;
    height: auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistName {
    margin: 2% auto;
  }
  .fullProfileColumn
    .artistsContainer
    .topArtistContainer
    .artistContainer
    .artistName
    p {
    width: max-content;
    height: max-content;
  }
  .switchButtonContainer {
    width: 60px;
  }
  .switchButtonContainer .switchButton {
    width: 25px;
  }
  .tabMenuItem {
    min-height: 10vh;
  }
  .tabMenuItem .tabContentContainer {
    max-height: 12vh;
    max-width: 100%;
    margin: auto;
    font-size: 1rem;
  }
  .tabMenuItem .tabActionContainer {
    max-height: max-content;
    margin: auto;
    max-width: 3vh;
  }
  .tabMenuItem .tabActionContainer .tabMenuArrowContainer .tabMenuArrowImg {
    width: 5vw;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer {
    width: 60px;
    height: 45px;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer .switchButton {
    width: 25px;
  }
  .tabMenuItem .tabActionContainer .switchButtonContainer .switch {
    width: 55px;
  }
  .tabMenuHeaderContainer {
    min-height: 5vh;
  }
  .tabMenuHeaderContainer .tabHeaderContainer {
    margin: auto;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions
    .upgradeAccountCancelSubscriptionContainer,
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions
    .upgradeAccountUpgradeButtonContainer {
    min-width: max-content;
    max-width: max-content;
    min-height: max-content;
    max-height: max-content;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtons
    .upgradeAccountFormActions {
    min-width: 50%;
    max-width: 50%;
  }
  .upgradeModalCard {
    margin: unset;
    width: 100%;
    position: relative;
    top: 10vh;
    height: 100vh;
    min-height: 100%;
  }
  .upgradeModalCard .upgradeModalCardHeader {
    min-height: 30vh;
    max-height: 30vh;
    padding-bottom: 10%;
  }
  .upgradeModalCard .upgradeModalCardBody {
    min-height: max-content;
  }
  .upgradeModalCard .upgradeModalCardBody .upgradeAccountButtonsContainer {
    padding: 10% 0;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer {
    max-width: 50vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer
    .badge {
    top: -2vh;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .badgeContainer
    .upgradeModalButton {
    min-width: 42vw;
    max-height: 25vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .upgradeModalButton {
    max-width: 50vw;
    max-height: 25vw;
  }
  .upgradeModalCard
    .upgradeModalCardBody
    .upgradeAccountButtonsContainer
    .upgradeAccountFormActions {
    min-width: max-content;
  }
  .popUpMenuContainer .popUpMenuButtonContainer .dotImg {
    width: 20px;
  }
  .popUpMenuContainer .popUpMenuItemContainerActive {
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
  .popUpMenuContainer .popUpMenuItemContainerHidden {
    top: -100vh;
    width: unset;
    height: unset;
    opacity: 1;
  }
  .input .inputIcon,
  .input .iconFocus {
    display: none;
  }
  .modalCard {
    width: 100vw;
    height: max-content;
    margin: 40vh 0;
  }
  .modalCard .modalContentContainer .modalCancelContainer {
    max-height: max-content;
  }
  .modalCard .modalContentContainer .modalCancelContainer .modalCancelImg {
    width: 20px;
  }
  .modalCard .modalContentContainer .modalTextContainer {
    max-height: max-content;
    margin: 5% 0;
  }
  .modalCard .modalContentContainer .modalButtonsContainer {
    max-height: max-content;
  }
  .modalCard .modalContentContainer .modalButtonsContainer .modalOptionButton {
    max-height: max-content;
  }
  .matchModalCard {
    width: 100%;
    height: 100%;
    margin-top: 0vh;
  }
  .matchModalCard .matchModalContentContainer {
    max-height: max-content;
    margin: auto;
  }
  .matchModalCard .matchModalContentContainer .matchModalText {
    width: 75%;
  }
  .matchModalCard .matchModalContentContainer .matchModalActions {
    max-height: 35%;
  }
}

@media all and (max-width: 400px) {
  .ModamilyAppHome {
    min-width: 100vw;
  }
  .banner {
    position: unset;
    width: inherit;
    text-align: unset;
    justify-content: unset;
    align-items: unset;
  }
  .homeBanner {
    margin-top: 5vh;
    background-size: auto 100%;
    background-position-x: 35%;
    max-width: 100%;
    height: 50vh;
  }
  .homeBanner .bannerContentContainer {
    max-width: max-content;
    margin: auto;
  }
  .homeBanner .bannerContentContainer .bannerText {
    font-size: 2rem;
    width: 75vw;
    margin: 0 auto;
    height: max-content;
  }
  .homeBanner .bannerContentContainer .bannerSubtitle {
    font-size: 1.2rem;
    width: 75vw;
    margin: 0 auto;
    text-align: center;
  }
  .homeBanner .bannerContentContainer .bannerAppBtn {
    width: 35vw;
    height: 10vh;
  }
  .signImage {
    display: none;
  }
  .goalSection {
    flex-direction: column;
    max-height: unset;
  }
  .goalSection .goalImage {
    width: 100%;
    height: max-content;
  }
  .goalSection .goalInfo {
    width: 100%;
  }
  .goalSection .goalInfo .goalInfoContentContainer {
    max-height: unset;
    margin: 5% auto;
  }
  .goalSection .goalInfo .goalInfoContentContainer .goalTitle {
    margin: 5% auto;
  }
  .goalSection .goalInfo .goalInfoContentContainer .goalBox {
    max-height: unset;
  }
  .goalSection .goalInfo .goalInfoContentContainer .goalBox .goalPointImg {
    width: 25px;
    margin: auto 5%;
  }
  .goalSection .goalInfo .goalInfoContentContainer .goalBox .goalPointText {
    font-size: 0.8rem;
  }
  .conciergeBox {
    flex-direction: column;
  }
  .conciergeBox .conciergePhoto {
    width: 100%;
    height: auto;
  }
  .conciergeBox .conciergeBtnContainer {
    width: 25vw;
    margin: 0 auto;
    max-width: unset;
  }
  .newsletterBox {
    display: none;
  }
  .siteLinks {
    display: none;
  }
  .socialMediaBox {
    margin: unset;
    margin: 5% auto;
    min-width: 75vw;
  }
  .socialMediaBox a {
    margin: 0 auto;
  }
  .separator {
    display: none;
  }
  .articleContainer {
    max-height: max-content;
  }
  .articleContainer .articleBtn {
    width: 92vw;
    height: 5vh;
    margin: 2% auto;
  }
  .signPage .signInfo {
    margin: unset;
    max-width: 100%;
  }
  .signPage .signInfo .signContentContainer {
    max-width: 90vw;
    margin: 15% auto;
  }
  .signPage .signInfo .signContentContainer .signTitle {
    text-align: center;
  }
  .signPage .signInfo .signContentContainer .signSubtitle {
    text-align: center;
  }
  .signPage .signInfo .signContentContainer .signPgBtnContainer {
    flex-direction: column;
    max-width: max-content;
    max-height: max-content;
    margin: 0 auto;
  }
  .signPage .signInfo .signContentContainer .signPgBtnContainer .signPgBtn {
    max-width: 100%;
  }
  .partnerSection .enterpriseBox {
    flex-direction: column;
    margin: 4% auto;
    padding: 4% 0;
  }
  .partnerSection .enterpriseBox .enterpriseText {
    margin: 2% auto;
    max-width: max-content;
    text-align: center;
  }
  .partnerSection .enterpriseBox .enterpriseBtn {
    margin: unset;
    max-width: 75%;
  }
  .partnerSection .displayBoxesContainer {
    flex-direction: column;
    width: 95vw;
  }
  .partnerSection .displayBoxesContainer .partnerBox {
    max-width: 80vw;
    margin: 10% auto;
    flex-direction: column;
    max-height: max-content;
  }
  .partnerSection .displayBoxesContainer .partnerBox .partnerImg {
    max-height: auto;
    width: 40vw;
  }
  .copyright {
    margin: 5% auto;
    font-size: 0.6rem;
    width: max-content;
    line-height: unset;
    vertical-align: unset;
    text-align: unset;
  }
}

@media all and (max-width: 400px) {
  .ModamilyAppRegisterLogin .pageContainer {
    margin: unset;
  }
  .ModamilyAppRegisterLogin .pageContainer .form {
    max-height: max-content;
  }
  .ModamilyAppRegisterLogin .pageContainer .form .passwordRequirements {
    max-height: max-content;
  }
  .ModamilyAppRegisterLogin
    .pageContainer
    .form
    .passwordRequirements
    .purpleText {
    font-size: 0.8rem;
  }
  .ModamilyAppRegisterLogin
    .pageContainer
    .form
    .inputContainer
    .inputLabelText {
    font-size: 0.7rem;
    letter-spacing: 0.15rem;
  }
  .ModamilyAppRegisterLogin .pageContainer .form .signFormBtnContainer {
    max-width: unset;
    min-height: max-content;
    margin: unset;
  }
  .ModamilyAppRegisterLogin .pageContainer .form .formSuggestionTextContainer {
    max-height: max-content;
  }
  .ModamilyAppRegisterLogin
    .pageContainer
    .form
    .formSuggestionTextContainer
    .formSuggestionText,
  .ModamilyAppRegisterLogin
    .pageContainer
    .form
    .formSuggestionTextContainer
    a
    p {
    font-size: 1rem;
  }
  .ModamilyAppRegisterLogin .footer .footerHeaderText {
    font-size: 1rem;
  }
  .ModamilyAppRegisterLogin .footer .socialButtons {
    max-width: max-content;
    margin: auto;
  }
  .ModamilyAppRegisterLogin .footer .socialButtons .googleLoginButton {
    width: 15vw;
    height: 15vw;
  }
  .ModamilyAppRegisterLogin .footer .socialButtons span {
    max-width: max-content;
  }
  .ModamilyAppRegisterLogin .footer .socialButtons span .facebookLoginButton {
    width: 15vw;
    height: 15vw;
  }
}

@media all and (max-width: 400px) {
  .ModamilyAppConfirmEmailPage .pageContainer {
    margin: unset;
    min-height: 100vh;
  }
  .ModamilyAppConfirmEmailPage .pageContainer .confirmEmailPage {
    margin: 25% auto;
    max-height: unset;
    width: 80vw;
  }
  .ModamilyAppConfirmEmailPage
    .pageContainer
    .confirmEmailPage
    .formHeaderText {
    font-size: 1rem;
  }
  .ModamilyAppConfirmEmailPage .footer .rowButtonsContainer {
    max-width: 80vw;
    min-width: 80vw;
    max-height: 10vh;
    margin: auto;
  }
  .ModamilyAppConfirmEmailPage
    .footer
    .rowButtonsContainer
    .confirmEmailButtonContainer {
    max-height: 8vh;
    width: 20vw;
  }
}

@media all and (max-width: 400px) {
  .ModamilyAppOnboarding .onboardingContainer {
    min-height: 80vh;
  }
  .ModamilyAppOnboarding .onboardingContainer .onboardingFormContainer {
    margin: 10% auto;
    width: 90%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .textArea
    textarea {
    width: 60vw;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .convoFormButtonContainer {
    width: 45vw;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .selectedQuestionContainer {
    max-width: unset;
    width: 75%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .selectedQuestionContainer
    .cardContainer {
    margin: auto;
    min-width: 75%;
    width: 80%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .conversationCardContainer
    .convoCardContainer {
    max-width: unset;
    width: 75%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .conversationCardContainer
    .convoCardContainer
    .conversationCard {
    width: 80%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .onboardingImageListContainer
    .onboardingPhotoGrid {
    margin: auto;
    max-width: unset;
    width: 80%;
    height: 20vh;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .onboardingImageListContainer
    .onboardingPhotoGrid
    .draggableImageContainer {
    max-width: 30%;
    margin: 1%;
    max-height: unset;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .genderForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .sexualOrientationForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .lookingForForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .ModamilyAppIncome,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .nameForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .educationForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .workForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .hometownForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .currentLocationForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .birthdayForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .religionForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .politicsForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .heightForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .eyeColorForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .hairColorForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .raceForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .ethnicityForm,
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .coparentRanksForm {
    width: 90%;
  }
  .ModamilyAppOnboarding
    .onboardingContainer
    .onboardingFormContainer
    .rankQualityContainer {
    min-height: max-content;
  }
  .ModamilyAppOnboarding .footer {
    max-height: 15vh;
  }
  .ModamilyAppOnboarding .footer .onboardingButtonContainer {
    width: 80%;
    max-height: max-content;
    margin: auto;
  }
  .ModamilyAppOnboarding
    .footer
    .onboardingButtonContainer
    .onboardingStepButton {
    height: max-content;
  }
}

@media all and (max-width: 400px) {
  .ModamilyAppBrowse .browseCardScrollContainer .browseCarouselCard {
    flex-direction: column;
    max-width: 85vw;
    max-height: 75vh;
    width: 85vw;
    height: 75vh;
  }
  .ModamilyAppBrowse
    .browseCardScrollContainer
    .browseCarouselCard
    .profileInfoContainer {
    max-height: unset;
  }
  .ModamilyAppBrowse
    .browseCardScrollContainer
    .browseCarouselCard
    .profileInfoContainer
    div {
    min-height: max-content;
  }
  .ModamilyAppBrowse .carouselAlert {
    max-width: unset;
    width: 80vw;
  }
  .ModamilyAppBrowse .carouselAlert .cardMessage {
    margin: 0 auto;
  }
  .ModamilyAppBrowse .carouselAlert .cardMessage p {
    font-size: 1.3rem;
  }
  .ModamilyAppBrowse .carouselAlert .carouselControlContainer {
    min-height: max-content;
  }
  .ModamilyAppBrowse .carouselAlert .carouselControlContainer .carouselRefresh {
    min-width: 45px;
    width: 45px;
    min-height: 45px;
    max-height: 45px;
  }
  .ModamilyAppBrowse
    .carouselAlert
    .carouselControlContainer
    .carouselRefresh
    .reloadImage {
    width: 35px;
    height: auto;
  }
}

@media all and (max-width: 400px) {
  .likesPageContainer {
    margin: 12vh 5%;
  }
  .likesPageContainer .userLikesContainer .userLikesRow {
    margin: 0 auto;
    overflow-x: auto;
  }
  .likesPageContainer .likeUserContainer {
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    margin: 1%;
    max-width: max-content;
  }
  .likesPageContainer .likesUserContainer .noUsersLiked {
    width: 75vw;
    left: 12.5%;
  }
  .likesPageContainer .likesUserContainer .noUsersLiked .noUsersLikedText {
    max-width: 80%;
    text-align: center;
  }
  .likesPageContainer .likesUserContainer .likedUserGrid {
    flex-direction: column;
    /* max-width: 75vw; */
    margin: auto;
  }
  .likesPageContainer .likesUserContainer .likeUserCard {
    max-width: 75vw;
    width: 75vw;
  }
}

@media all and (max-width: 400px) {
  .ModamilyAppMessages {
    max-width: 100vw;
  }
  .ModamilyAppMessages .likesPageContainer {
    max-height: 100vh;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .matchesContainer
    .matchListContainer
    .likeUserContainer {
    min-width: max-content;
    max-width: max-content;
  }
  .ModamilyAppMessages .likesPageContainer .convosContainer {
    margin: 5% 0;
  }
  .ModamilyAppMessages .likesPageContainer .messageChatCard {
    margin: 5vh 0;
    max-width: unset;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .browseCardPopUpMenuContainer {
    margin-top: 2.5vh;
    margin-right: 5vw;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatHeaderContainer {
    min-width: max-content;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatHeaderContainer
    .messageChatHeaderImageContainer {
    margin: 0 auto;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .messageChatBody
    .chatView
    .chatMessageContainer
    .chatMessage
    p {
    word-break: break-word;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .chatTextInputContainer
    .sendImageButton {
    max-width: 10vw;
    margin-right: 2%;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .messageChatCard
    .chatTextInputContainer
    .messageChatButton {
    max-width: 20vw;
  }
  .ModamilyAppMessages .likesPageContainer .convoHeaderContainer {
    flex-direction: column;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoHeaderContainer
    .sortingBoxContainer {
    flex-direction: column;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab {
    max-width: 100vw;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab
    .roundImageContainerRegular {
    max-width: max-content;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .conversationTab
    .roundImageContainerRegular
    .roundImage {
    margin: unset;
  }
  .ModamilyAppMessages
    .likesPageContainer
    .convoListContainer
    .recentMessageContainer
    p {
    word-break: break-word;
  }
  .ModamilyAppMessages .likesPageContainer .hiddenMessageModal {
    width: 0;
    height: 0;
  }
}

@media all and (max-width: 400px) {
  .ModamilyAppSettings .accountDetails,
  .ModamilyAppSettings .searchPreferencesPage,
  .ModamilyAppSettings .editProfileMenu {
    margin-top: 10.5vh;
  }
  .ModamilyAppSettings .pageContainer {
    margin: 10vh 5%;
  }
  .ModamilyAppSettings .pageContainer .searchPreferencesPage {
    margin-top: unset;
  }
  .ModamilyAppSettings
    .pageContainer
    .searchPreferencesPage
    .searchPrefLookingForFormContainer {
    max-width: max-content;
    width: max-content;
    margin: unset;
  }
  .ModamilyAppSettings .searchPreferencesMenu {
    margin: unset;
  }
  .ModamilyAppSettings .searchPreferencesMenu .upgradeCard {
    min-width: 75%;
  }
  .ModamilyAppSettings .searchPreferencesMenu .upgradeCard .upgradeCardHeader {
    min-width: 100%;
  }
  .ModamilyAppSettings .searchPrefForm {
    width: 90vw;
  }
  .ModamilyAppSettings .searchPrefForm .searchPrefDistanceFormContainer {
    width: unset;
    height: unset;
    margin: unset;
  }
  .ModamilyAppSettings .dangerousActions {
    min-height: 25vh;
  }
  .ModamilyAppSettings .dangerousActions .deactivateAccountButtonContainer {
    width: 35vw;
    max-height: 5vh;
    margin: auto;
  }
  .ModamilyAppSettings .dangerousActions .logoutButtonContainer {
    margin: auto;
    width: 55vw;
    max-height: 5vh;
  }
  .ModamilyAppSettings .editProfileMenu .photoGrid {
    margin: 5% auto;
    max-width: 90%;
  }
  .ModamilyAppSettings .editProfileMenu .photoGrid .draggableImageContainer {
    max-width: unset;
    max-height: unset;
  }
  .ModamilyAppSettings .editProfileMenu .convoCardContainer {
    max-width: unset;
    width: 75%;
  }
  .ModamilyAppSettings .editProfileMenu .convoCardContainer .conversationCard {
    width: 80%;
  }
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .genderForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .sexualOrientationForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .lookingForForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .ModamilyAppIncome,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .nameForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .educationForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .workForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .hometownForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .currentLocationForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .birthdayForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .religionForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .politicsForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .heightForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .eyeColorForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .hairColorForm,
  .ModamilyAppSettings .editProfileForm .editProfileFormContainer .raceForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .ethnicityForm,
  .ModamilyAppSettings
    .editProfileForm
    .editProfileFormContainer
    .coparentRanksForm {
    width: 90vw;
  }
}

@media all and (max-width: 400px) {
  .termsOfServicePage,
  .ModamilyPrivacyPolicy {
    margin: 10vh 0;
  }
  .termsOfServicePage .pageContainer,
  .ModamilyPrivacyPolicy .pageContainer {
    margin: 5%;
  }
}

@-moz-document url-prefix() {
  .banner {
    position: unset;
    width: inherit;
    text-align: unset;
    justify-content: unset;
    align-items: unset;
  }
  .homeBanner {
    margin-top: 5vh;
    background-position-x: 35%;
    max-width: 100%;
    height: 50vh;
  }
  .homeBanner .bannerContentContainer {
    margin: auto;
    max-height: 50vh;
    min-width: 100vh;
  }
  .homeBanner .bannerContentContainer .bannerText {
    font-size: 3rem;
    width: 75vw;
    margin: 0 auto;
    height: max-content;
  }
  .homeBanner .bannerContentContainer .bannerSubtitle {
    font-size: 1.2rem;
    width: 75vw;
    margin: 0 auto;
    text-align: center;
  }
  .homeBanner .bannerContentContainer .bannerAppBtn {
    width: 35vw;
    height: 10vh;
  }
}

html {
  font-family: 'WorkSans-Regular';
}

/* ModamilyAppBirthForm */
.birthdayForm .customDateInput {
  flex-direction: row;
}

.birthdayForm .customDateInput > input {
  width: 70px !important;
}
